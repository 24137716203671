import {
    ACTION_CONTACT,
    CREATE_CONTACT,
    ERROR_CREATE_CONTACT,
    ACTION_FETCH_CONTACT,
    FETCH_CONTACT,
    ERROR_FETCH_CONTACT,
    CLEAR_CONTACT,
    UPDATE_CONTACT,
    ERROR_UPDATE_CONTACT,
    CLEAR_ALERT_CONTACT,
    FETCH_CONTACTS,
    ERROR_FETCH_CONTACTS,
    ACTION_FETCH_CONTACTS,
    DELETE_CONTACT,
    ERROR_DELETE_CONTACT,
    ACTIVE_CONTACT,
    ERROR_ACTIVE_CONTACT,
    EMAIL_CONTACT,
    ERROR_EMAIL_CONTACT,
    ACTION_DATA_FIELDS_CONTACT,
    DATA_FIELDS_CONTACT,
    ERROR_DATA_FIELDS_CONTACT,
} from './types';

const INIT_STATE = {
    contacts: {
        data: [],
        totalItemCount: 0
    },
    contactData: {
        firm_id: '',
        first_name: '',
        last_name: '',
        email: '',
        job_title_id: '',
        gender_id: '',
        viber: '',
        whatsapp: '',
        skype: '',
        emails: [],
        phones: [],
        mobiles: [],
        active: 0,
        created_user: '',
        created_at: ''
    },
    dataFields: {
        genders: [],
        jobTitles: []
    },
    createContact: '',
    updateContact: '',
    deleteContact: '',
    activeContact: '',
    sendEmail: '',
    loadingBtn: false,
    loadingModal: false,
    loadingTab: false,
    loading: true,
    error: ''
};


 const contact = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_CONTACT:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_CONTACT:
            return { ...state, loadingBtn: false, createContact: action.payload, error: '' };
        case ERROR_CREATE_CONTACT:
            return { ...state, loadingBtn: false, createContact: '', error: action.payload.message };
        case ACTION_FETCH_CONTACT:
            return { ...state, loadingTab: true, error: '' };
        case FETCH_CONTACT:
            return { ...state, loadingTab: false, contactData: action.payload, error: '' };
        case ERROR_FETCH_CONTACT:
            return { ...state, loadingTab: false, contactData: '', error: action.payload.message };
        case CLEAR_CONTACT:
            return { ...state, loadingBtn: false, loading: false, loadingTab: false, contactData: INIT_STATE.contactData, error: '' };
        case UPDATE_CONTACT:
            return { ...state, loadingBtn: false, updateContact: action.payload, error: '' };
        case ERROR_UPDATE_CONTACT:
            return { ...state, loadingBtn: false, updateContact: '', error: action.payload.message };
        case CLEAR_ALERT_CONTACT:
            return { ...state, updateContact: '', createContact: '', deleteContact: '', activeContact: '', sendEmail: '', error: '' };
        case ACTION_FETCH_CONTACTS:
            return { ...state, loading: true, error: '' };
        case FETCH_CONTACTS:
            return { ...state, loading: false, contacts: action.payload, error: '' };
        case ERROR_FETCH_CONTACTS:
            return { ...state, loading: false, contacts: INIT_STATE.contacts, error: action.payload.message };
        case DELETE_CONTACT:
            return { ...state, loadingTab: false, deleteContact: action.payload, error: '' };
        case ERROR_DELETE_CONTACT:
            return { ...state, loadingTab: false, deleteContact: '', error: action.payload.message };
        case ACTIVE_CONTACT:
            return { ...state, loadingTab: false, activeContact: action.payload, error: '' };
        case ERROR_ACTIVE_CONTACT:
            return { ...state, loadingTab: false, activeContact: '', error: action.payload.message };
        case EMAIL_CONTACT:
            return { ...state, loadingTab: false, sendEmail: action.payload, error: '' };
        case ERROR_EMAIL_CONTACT:
            return { ...state, loadingTab: false, sendEmail: '', error: action.payload.message };

        //fetch data for fields
        case ACTION_DATA_FIELDS_CONTACT:
            return { ...state, loading: true, error: '' };
        case DATA_FIELDS_CONTACT:
            return { ...state, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_CONTACT:
            return { ...state, loading: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };
        
        default: return state;
    }
}

export default contact;
