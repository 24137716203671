import {
    ACTION_BANK,
    CREATE_BANK,
    ERROR_CREATE_BANK,
    ACTION_FETCH_BANK,
    FETCH_BANK,
    ERROR_FETCH_BANK,
    CLEAR_BANK,
    UPDATE_BANK,
    ERROR_UPDATE_BANK,
    CLEAR_ALERT_BANK,
    FETCH_BANKS,
    ERROR_FETCH_BANKS,
    ACTION_FETCH_BANKS,
    DELETE_BANK,
    ERROR_DELETE_BANK,
    ACTIVE_BANK,
    ERROR_ACTIVE_BANK,
    EMAIL_BANK,
    ERROR_EMAIL_BANK,
    ACTION_DATA_FIELDS_BANK,
    DATA_FIELDS_BANK,
    ERROR_DATA_FIELDS_BANK,
    SET_CURRENT_PAGE_BANK,
    SET_SORT_BANK,
    SET_SELECTED_PAGE_SIZE_BANK,
    SET_SEARCH_BANK,
    SET_SEARCH_VALUE_BANK,
} from './types';

const INIT_STATE = {
    banks: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 10,
    search: "",
    searchValue: "",
    currentPage: 1,
    sortObj: null,
    bankData: {
        bank_name: '',
        account: '',
        iban: '',
        swift: '',
        businesses: []
    },
    createBank: '',
    updateBank: '',
    deleteBank: '',
    activeBank: '',
    sendEmail: '',
    dataFields: {
        businesses: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
};


const bank = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_BANK:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_BANK:
            return { ...state, loadingBtn: false, createBank: action.payload, error: '' };
        case ERROR_CREATE_BANK:
            return { ...state, loadingBtn: false, createBank: '', error: action.payload.message };
        case ACTION_FETCH_BANK:
            return { ...state, loading: true, error: '' };
        case FETCH_BANK:
            return { ...state, loading: false, bankData: action.payload, error: '' };
        case ERROR_FETCH_BANK:
            return { ...state, loading: false, bankData: '', error: action.payload.message };
        case CLEAR_BANK:
            return { ...state, loadingBtn: false, loading: false, bankData: INIT_STATE.bankData, error: '' };
        case UPDATE_BANK:
            return { ...state, loadingBtn: false, updateBank: action.payload, error: '' };
        case ERROR_UPDATE_BANK:
            return { ...state, loadingBtn: false, updateBank: '', error: action.payload.message };
        case CLEAR_ALERT_BANK:
            return { ...state, updateBank: '', createBank: '', deleteBank: '', activeBank: '', changePasswordBank: '', sendEmail: '', error: '' };
        case ACTION_FETCH_BANKS:
            return { ...state, loading: true, error: '' };
        case FETCH_BANKS:
            return { ...state, loading: false, banks: action.payload, error: '' };
        case ERROR_FETCH_BANKS:
            return { ...state, loading: false, banks: INIT_STATE.banks, error: action.payload.message };
        case DELETE_BANK:
            return { ...state, loading: false, deleteBank: action.payload, error: '' };
        case ERROR_DELETE_BANK:
            return { ...state, loading: false, deleteBank: '', error: action.payload.message };
        case ACTIVE_BANK:
            return { ...state, loading: false, activeBank: action.payload, error: '' };
        case ERROR_ACTIVE_BANK:
            return { ...state, loading: false, activeBank: '', error: action.payload.message };
        case EMAIL_BANK:
            return { ...state, loading: false, sendEmail: action.payload, error: '' };
        case ERROR_EMAIL_BANK:
            return { ...state, loading: false, sendEmail: '', error: action.payload.message };

        //fetch data for fields
        case ACTION_DATA_FIELDS_BANK:
            return { ...state, loading: true, error: '' };
        case DATA_FIELDS_BANK:
            return { ...state, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_BANK:
            return { ...state, loading: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };

        //seting list heading
        case SET_CURRENT_PAGE_BANK:
            return { ...state, currentPage: action.payload };
        case SET_SORT_BANK:
            return { ...state, sortObj: action.payload };
        case SET_SELECTED_PAGE_SIZE_BANK:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_SEARCH_BANK:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE_BANK:
            return { ...state, searchValue: action.payload };
        default: return state;
    }
}

export default bank
