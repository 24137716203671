/**************** USER *********************/
export const ACTION_USER = "ACTION_USER";
export const CREATE_USER = "CREATE_USER";
export const ERROR_CREATE_USER = "ERROR_CREATE_USER";

export const ACTION_FETCH_USER = "ACTION_FETCH_USER";
export const FETCH_USER = "FETCH_USER";
export const ERROR_FETCH_USER = "ERROR_FETCH_USER";
export const CLEAR_USER = "CLEAR_USER";

export const UPDATE_USER = "UPDATE_USER";
export const ERROR_UPDATE_USER = "ERROR_UPDATE_USER";

export const CLEAR_ALERT_USER = "CLEAR_ALERT_USER";

export const FETCH_USERS = "FETCH_USERS";
export const ACTION_FETCH_USERS = "ACTION_FETCH_USERS";
export const ERROR_FETCH_USERS = "ERROR_FETCH_USERS";

export const DELETE_USER = "DELETE_USER";
export const ERROR_DELETE_USER = "ERROR_DELETE_USER";

export const ACTIVE_USER = "ACTIVE_USER";
export const ERROR_ACTIVE_USER = "ERROR_ACTIVE_USER";

export const WELCOME_USER = "WELCOME_USER";
export const ERROR_WELCOME_USER = "ERROR_WELCOME_USER";

export const CHANGE_PASSWORD_USER = "CHANGE_PASSWORD_USER";
export const ERROR_CHANGE_PASSWORD_USER = "ERROR_CHANGE_PASSWORD_USER";

export const CHANGE_EMAIL_PASSWORD_USER = "CHANGE_EMAIL_PASSWORD_USER";
export const ERROR_CHANGE_EMAIL_PASSWORD_USER = "ERROR_CHANGE_EMAIL_PASSWORD_USER";

export const ACTION_DATA_FIELDS_USER = "ACTION_DATA_FIELDS_USER";
export const DATA_FIELDS_USER = "DATA_FIELDS_USER";
export const ERROR_DATA_FIELDS_USER = "ERROR_DATA_FIELDS_USER";

export const SET_CURRENT_PAGE_USER = "SET_CURRENT_PAGE_USER";
export const SET_SORT_USER = "SET_SORT_USER";
export const SET_SELECTED_PAGE_SIZE_USER = "SET_SELECTED_PAGE_SIZE_USER";
export const SET_SEARCH_USER = "SET_SEARCH_USER";
export const SET_SEARCH_VALUE_USER = "SET_SEARCH_VALUE_USER";




