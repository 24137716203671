import {
    ACTION_BUSINESS,
    CREATE_BUSINESS,
    ERROR_CREATE_BUSINESS,
    ACTION_FETCH_BUSINESS,
    FETCH_BUSINESS,
    ERROR_FETCH_BUSINESS,
    CLEAR_BUSINESS,
    UPDATE_BUSINESS,
    ERROR_UPDATE_BUSINESS,
    CLEAR_ALERT_BUSINESS,
    FETCH_BUSINESSES,
    ERROR_FETCH_BUSINESSES,
    ACTION_FETCH_BUSINESSES,
    DELETE_BUSINESS,
    ERROR_DELETE_BUSINESS,
    ACTIVE_BUSINESS,
    ERROR_ACTIVE_BUSINESS,
    ACTION_DATA_FIELDS_BUSINESS,
    DATA_FIELDS_BUSINESS,
    ERROR_DATA_FIELDS_BUSINESS,
    SET_CURRENT_PAGE_BUSINESS,
    SET_SORT_BUSINESS,
    SET_SELECTED_PAGE_SIZE_BUSINESS,
    SET_SEARCH_BUSINESS,
    SET_SEARCH_VALUE_BUSINESS,
} from './types';

const INIT_STATE = {
    businesses: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 10,
    search: "",
    searchValue: "",
    currentPage: 1,
    sortObj: null,
    businessData: {
        company_title: '',
        company_name: '',
        city: '',
        address: '',
        postal: '',
        vat: '',
        doy: '',
        gemi: '',
        email: '',
        website: '',
        phone: '',
        phone_code: '',
        phone_iso: '',
        country_id: ''
    },
    createBusiness: '',
    updateBusiness: '',
    deleteBusiness: '',
    activeBusiness: '',
    dataFields: {
        countries: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
};


const business = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_BUSINESS:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_BUSINESS:
            return { ...state, loadingBtn: false, createBusiness: action.payload, error: '' };
        case ERROR_CREATE_BUSINESS:
            return { ...state, loadingBtn: false, createBusiness: '', error: action.payload.message };
        case ACTION_FETCH_BUSINESS:
            return { ...state, loading: true, error: '' };
        case FETCH_BUSINESS:
            return { ...state, loading: false, businessData: action.payload, error: '' };
        case ERROR_FETCH_BUSINESS:
            return { ...state, loading: false, businessData: '', error: action.payload.message };
        case CLEAR_BUSINESS:
            return { ...state, loadingBtn: false, loading: false, businessData: INIT_STATE.businessData, error: '' };
        case UPDATE_BUSINESS:
            return { ...state, loadingBtn: false, updateBusiness: action.payload, error: '' };
        case ERROR_UPDATE_BUSINESS:
            return { ...state, loadingBtn: false, updateBusiness: '', error: action.payload.message };
        case CLEAR_ALERT_BUSINESS:
            return { ...state, updateBusiness: '', createBusiness: '', deleteBusiness: '', activeBusiness: '', changePasswordBusiness: '', error: '' };
        case ACTION_FETCH_BUSINESSES:
            return { ...state, loading: true, error: '' };
        case FETCH_BUSINESSES:
            return { ...state, loading: false, businesses: action.payload, error: '' };
        case ERROR_FETCH_BUSINESSES:
            return { ...state, loading: false, businesses: INIT_STATE.businesses, error: action.payload.message };
        case DELETE_BUSINESS:
            return { ...state, loading: false, deleteBusiness: action.payload, error: '' };
        case ERROR_DELETE_BUSINESS:
            return { ...state, loading: false, deleteBusiness: '', error: action.payload.message };
        case ACTIVE_BUSINESS:
            return { ...state, loading: false, activeBusiness: action.payload, error: '' };
        case ERROR_ACTIVE_BUSINESS:
            return { ...state, loading: false, activeBusiness: '', error: action.payload.message };

        //fetch data for fields
        case ACTION_DATA_FIELDS_BUSINESS:
            return { ...state, loading: true, error: '' };
        case DATA_FIELDS_BUSINESS:
            return { ...state, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_BUSINESS:
            return { ...state, loading: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };

        //seting list heading
        case SET_CURRENT_PAGE_BUSINESS:
            return { ...state, currentPage: action.payload };
        case SET_SORT_BUSINESS:
            return { ...state, sortObj: action.payload };
        case SET_SELECTED_PAGE_SIZE_BUSINESS:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_SEARCH_BUSINESS:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE_BUSINESS:
            return { ...state, searchValue: action.payload };
        default: return state;
    }
}

export default business
