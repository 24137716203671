/**************** BANK *********************/
export const ACTION_BANK = "ACTION_BANK";
export const CREATE_BANK = "CREATE_BANK";
export const ERROR_CREATE_BANK = "ERROR_CREATE_BANK";

export const ACTION_FETCH_BANK = "ACTION_FETCH_BANK";
export const FETCH_BANK = "FETCH_BANK";
export const ERROR_FETCH_BANK = "ERROR_FETCH_BANK";
export const CLEAR_BANK = "CLEAR_BANK";

export const UPDATE_BANK = "UPDATE_BANK";
export const ERROR_UPDATE_BANK = "ERROR_UPDATE_BANK";

export const CLEAR_ALERT_BANK = "CLEAR_ALERT_BANK";

export const FETCH_BANKS = "FETCH_BANKS";
export const ACTION_FETCH_BANKS = "ACTION_FETCH_BANKS";
export const ERROR_FETCH_BANKS = "ERROR_FETCH_BANKS";

export const DELETE_BANK = "DELETE_BANK";
export const ERROR_DELETE_BANK = "ERROR_DELETE_BANK";

export const ACTIVE_BANK = "ACTIVE_BANK";
export const ERROR_ACTIVE_BANK = "ERROR_ACTIVE_BANK";

export const EMAIL_BANK = "EMAIL_BANK";
export const ERROR_EMAIL_BANK = "ERROR_EMAIL_BANK";

export const ACTION_DATA_FIELDS_BANK = "ACTION_DATA_FIELDS_BANK";
export const DATA_FIELDS_BANK = "DATA_FIELDS_BANK";
export const ERROR_DATA_FIELDS_BANK = "ERROR_DATA_FIELDS_BANK";

export const SET_CURRENT_PAGE_BANK = "SET_CURRENT_PAGE_BANK";
export const SET_SORT_BANK = "SET_SORT_BANK";
export const SET_SELECTED_PAGE_SIZE_BANK = "SET_SELECTED_PAGE_SIZE_BANK";
export const SET_SEARCH_BANK = "SET_SEARCH_BANK";
export const SET_SEARCH_VALUE_BANK = "SET_SEARCH_VALUE_BANK";




