import {
    ACTION_MAP,
    CLEAR_MAP,
    CLEAR_ALERT_MAP,
    FETCH_ORDERS_MAP,
    ERROR_FETCH_ORDERS_MAP,
    ACTION_FETCH_ORDERS_MAP,
    ACTION_INFO_MODAL_MAP,
    INFO_MODAL_MAP,
    ERROR_INFO_MODAL_MAP,
    SET_FILTER_MAP,
    CLEAR_FILTER_MAP
} from './types';

//import moment from "moment";

const INIT_STATE = {
    orders: {
        data: [],
        loading_markers: [],
        delivery_markers: [],
        combined_markers: [],
        dataFields: []
    },
    filter: {
        type: 1,
        duration: 1,
        customers: '',
        carriers: '',
        date_from: null,
        date_to: null,
        // delivery_date_from: moment().format('YYYY-MM-DD'),
        // delivery_date_to: moment().add(7, 'days').format('YYYY-MM-DD'),
        delivery_date_from: null,
        delivery_date_to: null,
        loading_date_from: null,
        loading_date_to: null,
    },
    infoModal: [],
    loading: true,
    loadingFilter: true,
    loadingModal: true,
    error: ''
};

const map = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_MAP:
            return { ...state, loading: true, error: '' };
        case CLEAR_MAP:
            return { ...state, loading: true, orders: INIT_STATE.orders, filter: INIT_STATE.filter, error: '' };
        case CLEAR_ALERT_MAP:
            return { ...state, error: '' };
        case ACTION_FETCH_ORDERS_MAP:
            return { ...state, loading: action.payload.loading, loadingFilter: true, error: '' };
        case FETCH_ORDERS_MAP:
            return { ...state, loading: false, loadingFilter: false, orders: action.payload, error: '' };
        case ERROR_FETCH_ORDERS_MAP:
            return { ...state, loading: false, loadingFilter: false, orders: INIT_STATE.orders, error: action.payload.message };
        case ACTION_INFO_MODAL_MAP:
            return { ...state, loadingModal: true, error: '' };
        case INFO_MODAL_MAP:
            return { ...state, loadingModal: false, infoModal: action.payload, error: '' };
        case ERROR_INFO_MODAL_MAP:
            return { ...state, loadingModal: false, infoModal: INIT_STATE.orders, error: action.payload.message };

        //seting list heading
        case SET_FILTER_MAP:
            return { ...state, filter: action.payload };
        case CLEAR_FILTER_MAP:
            return { ...state, filter: INIT_STATE.filter };
        default: return state;
    }
}

export default map;
