/**************** FIRMS *********************/
export const ACTION_FIRM = "ACTION_FIRM";
export const CREATE_FIRM = "CREATE_FIRM";
export const ERROR_CREATE_FIRM = "ERROR_CREATE_FIRM";

export const ACTION_FETCH_FIRM = "ACTION_FETCH_FIRM";
export const FETCH_FIRM = "FETCH_FIRM";
export const ERROR_FETCH_FIRM = "ERROR_FETCH_FIRM";
export const CLEAR_FIRM = "CLEAR_FIRM";

export const UPDATE_FIRM = "UPDATE_FIRM";
export const ERROR_UPDATE_FIRM = "ERROR_UPDATE_FIRM";

export const CLEAR_ALERT_FIRM = "CLEAR_ALERT_FIRM";

export const FETCH_FIRMS = "FETCH_FIRMS";
export const ACTION_FETCH_FIRMS = "ACTION_FETCH_FIRMS";
export const ERROR_FETCH_FIRMS = "ERROR_FETCH_FIRMS";

export const DELETE_FIRM = "DELETE_FIRM";
export const ERROR_DELETE_FIRM = "ERROR_DELETE_FIRM";

export const ACTIVE_FIRM = "ACTIVE_FIRM";
export const ERROR_ACTIVE_FIRM = "ERROR_ACTIVE_FIRM";

export const EMAIL_FIRM = "EMAIL_FIRM";
export const ERROR_EMAIL_FIRM = "ERROR_EMAIL_FIRM";

export const FETCH_REST_INSURANCE = "FETCH_REST_INSURANCE";
export const ERROR_FETCH_REST_INSURANCE = "ERROR_FETCH_REST_INSURANCE";

export const ACTION_DATA_FIELDS_FIRM = "ACTION_DATA_FIELDS_FIRM";
export const DATA_FIELDS_FIRM = "DATA_FIELDS_FIRM";
export const ERROR_DATA_FIELDS_FIRM = "ERROR_DATA_FIELDS_FIRM";

export const SET_SEARCH_FIRM = "SET_SEARCH_FIRM";
export const SET_SEARCH_VALUE_FIRM = "SET_SEARCH_VALUE_FIRM";
export const SET_FILTER_FIRM = "SET_FILTER_FIRM";
export const CLEAR_FILTER_FIRM = "CLEAR_FILTER_FIRM";

export const SET_CURRENT_PAGE_ORDER = "SET_CURRENT_PAGE_ORDER";




