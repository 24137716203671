import {
    ACTION_LOADING_FILE,
    ACTION_LOADING_FILE_BTN,
    FILE_CHECK_TOKEN_SUCCESS,
    FILE_CHECK_TOKEN_ERROR,
    UNLOCK_FILES_SUCCESS,
    UNLOCK_FILES_ERROR,
    DELETE_ORDER_FILE,
    ERROR_DELETE_ORDER_FILE,
    CLEAR_FILE,
    CLEAR_ALERT_FILE
} from './types';

const INIT_STATE = {
    isUnlock: {token: "", code: "", order_id: ""},
    checkToken: '',
    unlockFiles: '',
    deleteOrderFile: '',
    loading: true,
    loadingBtn: false,
    error: ''
};


const file = (state = INIT_STATE, action) => {  
    switch (action.type) {
        case ACTION_LOADING_FILE:
            return { ...state, loading: true, error: '' };
        case ACTION_LOADING_FILE_BTN:
            return { ...state, loadingBtn: true, error: '' };
        case FILE_CHECK_TOKEN_SUCCESS:
            return { ...state, loading: false, checkToken: action.payload, error: "" };
        case FILE_CHECK_TOKEN_ERROR:
            return { ...state, loading: false, checkToken: "", error: action.payload.message };
        case UNLOCK_FILES_SUCCESS:
            return { ...state, loadingBtn: false, unlockFiles: action.payload.status, isUnlock: {token: action.payload.token, code: action.payload.code, order_id: action.payload.order_id }, error: "" };
        case UNLOCK_FILES_ERROR:
            return { ...state, loadingBtn: false, unlockFiles: "", isUnlock: INIT_STATE.isUnlock, error: action.payload.message };
        case DELETE_ORDER_FILE:
            return { ...state, loading: false, deleteOrderFile: action.payload, error: '' };
        case ERROR_DELETE_ORDER_FILE:
            return { ...state, loading: false, deleteOrderFile: '', error: action.payload.message };
        case CLEAR_FILE:
            return { ...state, ...INIT_STATE };
        case CLEAR_ALERT_FILE:
            return { ...state, deleteOrderFile: '', error: '' };
        
        default: return state;
    }
}

export default file;
