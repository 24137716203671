import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux";

const SidebarContent = props => {
  const ref = useRef();
  const [mentis, setMentis] = useState(0);

  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement    
      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  },[]);  

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    if(mentis === 0){
      new MetisMenu("#side-menu");
      setMentis(1)
    }

    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")
    const items = ul.getElementsByTagName("a")
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
      }else{
        closeMenu(items[i])
      }
    } 

    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    } 

  }, [mentis,props.location.pathname,activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function closeMenu(item){
    item.classList.remove("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.remove("mm-show")
    }

    if (parent) {
      parent.classList.remove("mm-active")
      const parent2 = parent.parentElement    
      if (parent2) {
        parent2.classList.remove("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.remove("mm-active") // li
          parent3.childNodes[0].classList.remove("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.remove("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.remove("mm-show") // li
              parent5.childNodes[0].classList.remove("mm-active") // a tag
            }
          }
        }
      }
    }
  }

  return (
    <React.Fragment>
      
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref} className="sidebar-menu-scroll">
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("general.menu")} </li>
            {props.author.role === 5 ? 
            <>
            <li>
              <Link to="/app/dashboard/map" className="waves-effect">
                <i className="uil-map-marker-alt"></i>
                <span>{props.t("menu.map")}</span>
              </Link>
            </li>
            <li>
              <Link to="/app/calendar" className="waves-effect">
                <i className="uil-calender"></i>
                <span>{props.t("menu.calendar")}</span>
              </Link>
            </li>
            <li>
              <Link to="/app/bookings/list" className="waves-effect">
                <i className="uil-calendar-alt"></i>
                <span>{props.t("menu.bookings")}</span>
              </Link>
            </li>
            <li>
              <Link to="/app/orders/list" className="waves-effect">
                <i className="uil-edit"></i>
                <span>{props.t("menu.orders")}</span>
              </Link>
            </li>
            <li>
              <Link to="/app/stats" className="waves-effect">
                <i className="uil-chart-bar"></i>
                <span>{props.t("general.stats")}</span>
              </Link>
            </li>
            </>
            : 
            <>
            <li>
              <Link to="/app/dashboard/map" className="waves-effect">
                <i className="uil-map-marker-alt"></i>
                <span>{props.t("menu.map")}</span>
              </Link>
            </li>
            <li>
              <Link to="/app/calendar" className="waves-effect">
                <i className="uil-calender"></i>
                <span>{props.t("menu.calendar")}</span>
              </Link>
            </li>
            <li>
              <Link to="/app/bookings/list" className="waves-effect">
                <i className="uil-calendar-alt"></i>
                <span>{props.t("menu.bookings")}</span>
              </Link>
            </li>
            <li>
              <Link to="/app/orders/list" className="waves-effect">
                <i className="uil-edit"></i>
                <span>{props.t("menu.orders")}</span>
              </Link>
            </li>
            <li>
              <Link to="/app/groupages/list" className="waves-effect">
                <i className="uil-box"></i>
                <span>{props.t("menu.groupages")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow  waves-effect" onClick={(e) => document.body.clientWidth <= 991 ? e.stopPropagation() : null}>
                <i className="uil-map-pin-alt"></i>
                <span>{props.t("menu.places")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/app/places/list">{props.t("menu.places-list")}</Link>
                </li>
                <li>
                  <Link to="/app/places/create">{props.t("menu.create-place")}</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/app/firms" className="waves-effect">
                <i className="uil-building"></i>
                <span>{props.t("menu.firms")}</span>
              </Link>
            </li>
            <li>
              <Link to="/app/groups" className="waves-effect">
                <i className="uil-layer-group"></i>
                <span>{props.t("menu.groups")}</span>
              </Link>
            </li>

            <li className="menu-title">{props.t("general.system")}</li>
            {props.author.role === 1 || props.author.role === 2 ?
            <li>
              <Link to="/#" className="has-arrow  waves-effect" onClick={(e) => document.body.clientWidth <= 991 ? e.stopPropagation() : null}>
                <i className="uil-users-alt"></i>
                <span>{props.t("menu.users")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/app/users/list">{props.t("menu.users-list")}</Link>
                </li>
                <li>
                  <Link to="/app/users/create">{props.t("menu.create-user")}</Link>
                </li>
              </ul>
            </li>
            : null}
            {props.author.role === 1 || props.author.role === 2 ?
            <li>
              <Link to="/#" className="has-arrow  waves-effect" onClick={(e) => document.body.clientWidth <= 991 ? e.stopPropagation() : null}>
                <i className="uil-user-square"></i>
                <span>{props.t("menu.accounts")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/app/accounts/list">{props.t("menu.accounts-list")}</Link>
                </li>
                <li>
                  <Link to="/app/accounts/create">{props.t("menu.create-account")}</Link>
                </li>
              </ul>
            </li>
            : null}
            {props.author.role === 1 || props.author.role === 2 || props.author.role === 3 ?
            <li>
              <Link to="/#" className="has-arrow  waves-effect" onClick={(e) => document.body.clientWidth <= 991 ? e.stopPropagation() : null}>
                <i className="uil-apps"></i>
                <span>{props.t("menu.categories")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/app/categories/list/gender">{props.t("menu.gender-list")}</Link>
                </li>
                <li>
                  <Link to="/app/categories/list/job_title">{props.t("menu.job_title-list")}</Link>
                </li>
                <li>
                  <Link to="/app/categories/list/country">{props.t("menu.country-list")}</Link>
                </li>
                <li>
                  <Link to="/app/categories/list/trailer">{props.t("menu.trailer-list")}</Link>
                </li>
                <li>
                  <Link to="/app/categories/list/packing">{props.t("menu.packing-list")}</Link>
                </li>
                <li>
                  <Link to="/app/categories/list/cargo">{props.t("menu.cargo-list")}</Link>
                </li>
                <li>
                  <Link to="/app/categories/list/ambient">{props.t("menu.ambient-list")}</Link>
                </li>
                <li>
                  <Link to="/app/categories/list/firm">{props.t("menu.firm-list")}</Link>
                </li>
                <li>
                  <Link to="/app/categories/list/operation">{props.t("menu.operation-list")}</Link>
                </li>
                <li>
                  <Link to="/app/categories/list/load">{props.t("menu.load-list")}</Link>
                </li>
                <li>
                  <Link to="/app/categories/list/heard_about">{props.t("menu.heard_about-list")}</Link>
                </li>
                <li>
                  <Link to="/app/categories/list/file">{props.t("menu.file-list")}</Link>
                </li>
                <li>
                  <Link to="/app/categories/list/log">{props.t("menu.log-list")}</Link>
                </li>
                <li>
                  <Link to="/app/categories/list/term">{props.t("menu.term-list")}</Link>
                </li>
                <li>
                  <Link to="/app/categories/list/cost">{props.t("menu.cost-list")}</Link>
                </li>
                <li>
                  <Link to="/app/categories/list/reference">{props.t("menu.reference-list")}</Link>
                </li>
                <li>
                  <Link to="/app/categories/list/damage">{props.t("menu.damage-list")}</Link>
                </li>
              </ul>
            </li>
            : null}
            </>
            }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

const mapStateToProps = ({ authUser }) => {
  const { author } = authUser;
  return { author };
};


export default connect(mapStateToProps, {})(withRouter(withTranslation()(SidebarContent)))