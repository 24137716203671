/**************** MAP *********************/
export const ACTION_MAP = "ACTION_MAP";

export const CLEAR_MAP = "CLEAR_MAP";

export const CLEAR_ALERT_MAP = "CLEAR_ALERT_MAP";

export const FETCH_ORDERS_MAP = "FETCH_ORDERS_MAP";
export const ACTION_FETCH_ORDERS_MAP = "ACTION_FETCH_ORDERS_CALENDAR";
export const ERROR_FETCH_ORDERS_MAP = "ERROR_FETCH_ORDERS_CALENDAR";

export const ACTION_INFO_MODAL_MAP = "ACTION_INFO_MODAL_MAP";
export const INFO_MODAL_MAP = "INFO_MODAL_MAP";
export const ERROR_INFO_MODAL_MAP = "ERROR_INFO_MODAL_MAP";

export const SET_FILTER_MAP = "SET_FILTER_MAP";
export const CLEAR_FILTER_MAP = "CLEAR_FILTER_MAP";




