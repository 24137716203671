import {
    ACTION_FETCH_NOTIFICATIONS,
    FETCH_NOTIFICATIONS,
    ERROR_FETCH_NOTIFICATIONS,
    SEEN_NOTIFICATIONS,
    ERROR_SEEN_NOTIFICATIONS,
    READ_NOTIFICATION,
    ERROR_READ_NOTIFICATION,
    ALL_READ_NOTIFICATIONS,
    ERROR_ALL_READ_NOTIFICATIONS,
    SET_CURRENT_PAGE_NOTIFICATION,
    SET_SELECTED_PAGE_SIZE_NOTIFICATION,
    SET_NOTIFICATION,
    CLEAR_NOTIFICATIONS
} from './types';

const INIT_STATE = {
    notifications: {
        data: [],
        unseenCount: 0
    },
    selectedPageSize: 10,
    currentPage: 1,
    seenNotifications: '',
    readNotification: '',
    allReadNotifications: '',
    loading: false,
    error: ''
};


const notification = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_FETCH_NOTIFICATIONS:
            return { ...state, loading: true, error: '' };
        case FETCH_NOTIFICATIONS:
            let fetch_not = {...state.notifications}

            if(action.payload.data.length > 0){
                action.payload.data.forEach((item) => {
                    fetch_not.data.push(item);
                })
                fetch_not.unseenCount = action.payload.unseenCount;
            }

            return { ...state, loading: false, notifications: fetch_not, error: '' };
        case ERROR_FETCH_NOTIFICATIONS:
            return { ...state, loading: false, notifications: INIT_STATE.notifications, error: action.payload.message };
        case SEEN_NOTIFICATIONS:
            let seen = {...state.notifications}
            seen.unseenCount = 0;
            return { ...state, loading: false, seenNotifications: action.payload, notifications: seen, error: '' };
        case ERROR_SEEN_NOTIFICATIONS:
            return { ...state, loading: false, seenNotifications: '', error: action.payload.message };
        case READ_NOTIFICATION:
            let read = {...state.notifications}
            read.data[action.payload.index].read = 1;

            return { ...state, loading: false, readNotification: action.payload.status, notifications: read, error: '' };
        case ERROR_READ_NOTIFICATION:
            return { ...state, loading: false, readNotification: '', error: action.payload.message };
        case ALL_READ_NOTIFICATIONS:
            let allRead = {...state.notifications}

            allRead.data.forEach((item, i) => {
                allRead.data[i].read = 1;
            });

            return { ...state, loading: false, allReadNotifications: action.payload, notifications: allRead, error: '' };
        case ERROR_ALL_READ_NOTIFICATIONS:
            return { ...state, loading: false, allReadNotifications: '', error: action.payload.message };

        //seting list heading
        case SET_CURRENT_PAGE_NOTIFICATION:
            return { ...state, currentPage: action.payload };
        case SET_SELECTED_PAGE_SIZE_NOTIFICATION:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_NOTIFICATION:
            return { ...state, notifications: action.payload };
        case CLEAR_NOTIFICATIONS:
            return { ...state, notifications: INIT_STATE.notifications, currentPage: 1, seenNotifications: '', readNotification: '', allReadNotifications: '', loading: false, error: '' };

        default: return state;
    }
}

export default notification
