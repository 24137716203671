/**************** BOOKINGS *********************/
export const ACTION_BOOKING = "ACTION_BOOKING";
export const CREATE_BOOKING = "CREATE_BOOKING";
export const ERROR_CREATE_BOOKING = "ERROR_CREATE_BOOKING";

export const CLEAR_BOOKING = "CLEAR_BOOKING";
export const CLEAR_BOOKINGS = "CLEAR_BOOKINGS";

export const CLEAR_ALERT_BOOKING = "CLEAR_ALERT_BOOKING";

export const FETCH_BOOKINGS = "FETCH_BOOKINGS";
export const ACTION_FETCH_BOOKINGS = "ACTION_FETCH_BOOKINGS";
export const ERROR_FETCH_BOOKINGS = "ERROR_FETCH_BOOKINGS";

export const STATUS_BOOKING = "STATUS_BOOKING";
export const ERROR_STATUS_BOOKING = "ERROR_STATUS_BOOKING";

export const DELETE_BOOKING = "DELETE_BOOKING";
export const ERROR_DELETE_BOOKING = "ERROR_DELETE_BOOKING";

export const SET_CURRENT_PAGE_BOOKING = "SET_CURRENT_PAGE_BOOKING";
export const SET_SORT_BOOKING = "SET_SORT_BOOKING";
export const SET_SELECTED_PAGE_SIZE_BOOKING = "SET_SELECTED_PAGE_SIZE_BOOKING";
export const SET_SEARCH_BOOKING = "SET_SEARCH_BOOKING";
export const SET_SEARCH_VALUE_BOOKING = "SET_SEARCH_VALUE_BOOKING";
export const SET_FILTER_BOOKING = "SET_FILTER_BOOKING";
export const CLEAR_FILTER_BOOKING = "CLEAR_FILTER_BOOKING";




