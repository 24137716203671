/**************** CHAT *********************/

export const FETCH_CHAT = "FETCH_CHAT";
export const ACTION_FETCH_CHAT = "ACTION_FETCH_CHAT";
export const ERROR_FETCH_CHAT = "ERROR_FETCH_CHAT";

export const ADD_CHAT = "ADD_CHAT";
export const ERROR_ADD_CHAT = "ERROR_ADD_CHAT";

export const LOCATION_CHAT = "LOCATION_CHAT";
export const ERROR_LOCATION_CHAT = "ERROR_LOCATION_CHAT";

export const FILE_CHAT = "FILE_CHAT";
export const ERROR_FILE_CHAT = "ERROR_FILE_CHAT";

export const READ_CHAT = "READ_CHAT";
export const ERROR_READ_CHAT = "ERROR_READ_CHAT";

export const CHARGE_CHAT = "CHARGE_CHAT";
export const ERROR_CHARGE_CHAT = "ERROR_DCHARGE_CHAT";

export const DELETE_CHAT = "DELETE_CHAT";
export const ERROR_DELETE_CHAT = "ERROR_DELETE_CHAT";

export const SET_CHAT = "SET_CHAT";
export const CLEAR_CHAT = "CLEAR_CHAT";





