import {
    ACTION_GROUP,
    CREATE_GROUP,
    ERROR_CREATE_GROUP,
    ACTION_FETCH_GROUP,
    FETCH_GROUP,
    ERROR_FETCH_GROUP,
    CLEAR_GROUP,
    UPDATE_GROUP,
    ERROR_UPDATE_GROUP,
    CLEAR_ALERT_GROUP,
    FETCH_GROUPS,
    ERROR_FETCH_GROUPS,
    ACTION_FETCH_GROUPS,
    DELETE_GROUP,
    ERROR_DELETE_GROUP,
    ACTION_DATA_FIELDS_GROUP,
    DATA_FIELDS_GROUP,
    ERROR_DATA_FIELDS_GROUP,
    EMAIL_GROUP,
    ERROR_EMAIL_GROUP,
    CREATE_CONTACTS_GROUP,
    ERROR_CREATE_CONTACTS_GROUP,    
    DELETE_CONTACT_GROUP,
    ERROR_DELETE_CONTACT_GROUP,
    SET_SEARCH_GROUP,
    SET_SEARCH_VALUE_GROUP,
} from './types';

const INIT_STATE = {
    groups: {
        data: [],
        totalItemCount: 0
    },
    search: "",
    searchValue: "",
    groupData: {
        name: '',
        subject: '',
        message: '',
        created_user: '',
        created_at: '',
        selectedContacts: [],
        sendedFiles: [],
        emails: [],
        files: [],
        filesData: []
    },
    createGroup: '',
    updateGroup: '',
    deleteGroup: '',
    createContactsGroup: '',
    deleteContactGroup: '', 
    sendEmail: '',
    dataFields: {
        contacts: []
    },
    loadingBtn: false,
    loadingTab: false,
    loading: true,
    error: ''
};


const group =  (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_GROUP:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_GROUP:
            return { ...state, loadingBtn: false, createGroup: action.payload, error: '' };
        case ERROR_CREATE_GROUP:
            return { ...state, loadingBtn: false, createGroup: '', error: action.payload.message };
        case ACTION_FETCH_GROUP:
            return { ...state, loadingTab: true, error: '' };
        case FETCH_GROUP:
            return { ...state, loadingTab: false, groupData: action.payload, error: '' };
        case ERROR_FETCH_GROUP:
            return { ...state, loadingTab: false, groupData: '', error: action.payload.message };
        case CLEAR_GROUP:
            return { ...state, loadingBtn: false, loading: false, loadingTab: false, groupData: INIT_STATE.groupData, error: '' };
        case UPDATE_GROUP:
            return { ...state, loadingBtn: false, updateGroup: action.payload, error: '' };
        case ERROR_UPDATE_GROUP:
            return { ...state, loadingBtn: false, updateGroup: '', error: action.payload.message };
        case CLEAR_ALERT_GROUP:
            return { ...state, updateGroup: '', createGroup: '', deleteGroup: '', sendEmail: '', createContactsGroup: '', deleteContactGroup: '', error: '' };
        case ACTION_FETCH_GROUPS:
            return { ...state, loading: true, error: '' };
        case FETCH_GROUPS:
            return { ...state, loading: false, groups: action.payload, error: '' };
        case ERROR_FETCH_GROUPS:
            return { ...state, loading: false, groups: INIT_STATE.groups, error: action.payload.message };
        case DELETE_GROUP:
            return { ...state, loadingTab: false, deleteGroup: action.payload, error: '' };
        case ERROR_DELETE_GROUP:
            return { ...state, loadingTab: false, deleteGroup: '', error: action.payload.message };
        case EMAIL_GROUP:
            let groupData = {...state.groupData};
            groupData.emails = action.payload.emails;
            groupData.selectedContacts = action.payload.contacts;

            return { ...state, loadingBtn: false, sendEmail: action.payload.status, groupData: groupData, error: '' };
        case ERROR_EMAIL_GROUP:
            return { ...state, loadingBtn: false, sendEmail: '', error: action.payload.message };
        case CREATE_CONTACTS_GROUP:
            return { ...state, createContactsGroup: action.payload, error: '' };
        case ERROR_CREATE_CONTACTS_GROUP:
            return { ...state, createContactsGroup: '', error: action.payload.message };
        case DELETE_CONTACT_GROUP:
            return { ...state, deleteContactGroup: action.payload, error: '' };
        case ERROR_DELETE_CONTACT_GROUP:
            return { ...state, deleteContactGroup: '', error: action.payload.message };


        //fetch data for fields
        case ACTION_DATA_FIELDS_GROUP:
            return { ...state, loading: true, error: '' };
        case DATA_FIELDS_GROUP:
            return { ...state, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_GROUP:
            return { ...state, loading: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };

        case SET_SEARCH_GROUP:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE_GROUP:
            return { ...state, searchValue: action.payload };
        default: return state;
    }
}

export default group;
