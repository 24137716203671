import {
    ACTION_USER,
    CREATE_USER,
    ERROR_CREATE_USER,
    ACTION_FETCH_USER,
    FETCH_USER,
    ERROR_FETCH_USER,
    CLEAR_USER,
    UPDATE_USER,
    ERROR_UPDATE_USER,
    CLEAR_ALERT_USER,
    FETCH_USERS,
    ERROR_FETCH_USERS,
    ACTION_FETCH_USERS,
    DELETE_USER,
    ERROR_DELETE_USER,
    ACTIVE_USER,
    ERROR_ACTIVE_USER,
    WELCOME_USER,
    ERROR_WELCOME_USER,
    ACTION_DATA_FIELDS_USER,
    DATA_FIELDS_USER,
    ERROR_DATA_FIELDS_USER,
    CHANGE_PASSWORD_USER,
    ERROR_CHANGE_PASSWORD_USER,
    CHANGE_EMAIL_PASSWORD_USER,
    ERROR_CHANGE_EMAIL_PASSWORD_USER,
    SET_CURRENT_PAGE_USER,
    SET_SORT_USER,
    SET_SELECTED_PAGE_SIZE_USER,
    SET_SEARCH_USER,
    SET_SEARCH_VALUE_USER,
} from './types';

const INIT_STATE = {
    users: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 10,
    search: "",
    searchValue: "",
    currentPage: 1,
    sortObj: null,
    userData: {
        name: '',
        surname: '',
        phone: '',
        phone_code: '',
        phone_iso: '',
        mobile: '',
        mobile_code: '',
        mobile_iso: '',
        email: '',
        buisness_id: '',
        gender_id: '',
        job_title_id: '',
        role_id: '',
        isOnline: '',
        viber: '',
        whatsapp: '',
        skype: '',
        image: '',
        file: '',
    },
    createUser: '',
    updateUser: '',
    changePasswordUser: '',
    changeEmailPasswordUser: '',
    deleteUser: '',
    activeUser: '',
    welcomeUser: '',
    dataFields: {
        business: [],
        countries: [],
        jobTitles: [],
        genders: [],
        roles: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
};


const users =  (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_USER:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_USER:
            return { ...state, loadingBtn: false, createUser: action.payload, error: '' };
        case ERROR_CREATE_USER:
            return { ...state, loadingBtn: false, createUser: '', error: action.payload.message };
        case ACTION_FETCH_USER:
            return { ...state, loading: true, error: '' };
        case FETCH_USER:
            return { ...state, loading: false, userData: action.payload, error: '' };
        case ERROR_FETCH_USER:
            return { ...state, loading: false, userData: '', error: action.payload.message };
        case CLEAR_USER:
            return { ...state, loadingBtn: false, loading: false, userData: INIT_STATE.userData, error: '' };
        case UPDATE_USER:
            return { ...state, loadingBtn: false, updateUser: action.payload, error: '' };
        case ERROR_UPDATE_USER:
            return { ...state, loadingBtn: false, updateUser: '', error: action.payload.message };
        case CLEAR_ALERT_USER:
            return { ...state, updateUser: '', createUser: '', deleteUser: '', activeUser: '', welcomeUser: '', changePasswordUser: '', changeEmailPasswordUser: '', error: '' };
        case ACTION_FETCH_USERS:
            return { ...state, loading: true, error: '' };
        case FETCH_USERS:
            return { ...state, loading: false, users: action.payload, error: '' };
        case ERROR_FETCH_USERS:
            return { ...state, loading: false, users: INIT_STATE.users, error: action.payload.message };
        case DELETE_USER:
            return { ...state, loading: false, deleteUser: action.payload, error: '' };
        case ERROR_DELETE_USER:
            return { ...state, loading: false, deleteUser: '', error: action.payload.message };
        case ACTIVE_USER:
            return { ...state, loading: false, activeUser: action.payload, error: '' };
        case ERROR_ACTIVE_USER:
            return { ...state, loading: false, activeUser: '', error: action.payload.message };
        case WELCOME_USER:
            return { ...state, loading: false, welcomeUser: action.payload, error: '' };
        case ERROR_WELCOME_USER:
            return { ...state, loading: false, welcomeUser: '', error: action.payload.message };
        case CHANGE_PASSWORD_USER:
            return { ...state, loading: false, loadingBtn: false, changePasswordUser: action.payload, error: '' };
        case ERROR_CHANGE_PASSWORD_USER:
            return { ...state, loading: false, loadingBtn: false, changePasswordUser: '', error: action.payload.message };
        case CHANGE_EMAIL_PASSWORD_USER:
            return { ...state, loading: false, loadingBtn: false, changeEmailPasswordUser: action.payload, error: '' };
        case ERROR_CHANGE_EMAIL_PASSWORD_USER:
            return { ...state, loading: false, loadingBtn: false, changeEmailPasswordUser: '', error: action.payload.message };

        //fetch data for fields
        case ACTION_DATA_FIELDS_USER:
            return { ...state, loading: true, error: '' };
        case DATA_FIELDS_USER:
            return { ...state, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_USER:
            return { ...state, loading: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };

        //seting list heading
        case SET_CURRENT_PAGE_USER:
            return { ...state, currentPage: action.payload };
        case SET_SORT_USER:
            return { ...state, sortObj: action.payload };
        case SET_SELECTED_PAGE_SIZE_USER:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_SEARCH_USER:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE_USER:
            return { ...state, searchValue: action.payload };
        default: return state;
    }
}

export default users
