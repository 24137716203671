/**************** NOTIFICATION *********************/

export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const ACTION_FETCH_NOTIFICATIONS = "ACTION_FETCH_NOTIFICATIONS";
export const ERROR_FETCH_NOTIFICATIONS = "ERROR_FETCH_NOTIFICATIONS";

export const SEEN_NOTIFICATIONS = "SEEN_NOTIFICATIONS";
export const ERROR_SEEN_NOTIFICATIONS = "ERROR_SEEN_NOTIFICATIONS";

export const READ_NOTIFICATION = "READ_NOTIFICATION";
export const ERROR_READ_NOTIFICATION = "ERROR_READ_NOTIFICATION";

export const ALL_READ_NOTIFICATIONS = "ALL_READ_NOTIFICATIONS";
export const ERROR_ALL_READ_NOTIFICATIONS = "ERROR_ALL_READ_NOTIFICATIONS";

export const SET_CURRENT_PAGE_NOTIFICATION = "SET_CURRENT_PAGE_NOTIFICATION";
export const SET_SELECTED_PAGE_SIZE_NOTIFICATION = "SET_SELECTED_PAGE_SIZE_NOTIFICATION";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";





