import {
    ACTION_CALENDAR,
    CLEAR_ALERT_CALENDAR,
    FETCH_ORDERS_CALENDAR,
    ERROR_FETCH_ORDERS_CALENDAR,
    ACTION_FETCH_ORDERS_CALENDAR,
    SET_FILTER_CALENDAR,
    CLEAR_FILTER_CALENDAR,
    ACTION_SEARCH_FIELDS_CALENDAR,
    SEARCH_FIELDS_CALENDAR,
    ERROR_SEARCH_FIELDS_CALENDAR
} from './types';

const INIT_STATE = {
    orders: {
        data: []
    },
    fields: {},
    filter: {
        type: 1,
        business: "",
        customers: '',
        carriers: '',
        operations: '',
        date_from: '',
        date_to: '',
        pn_truck: '',
        pn_trailer: '',
        order_id: '',
        ref_number: ''
    },
    loading: true,
    error: ''
};

const calendar = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_CALENDAR:
            return { ...state, loading: true, error: '' };
        case CLEAR_ALERT_CALENDAR:
            return { ...state, error: '' };
        case ACTION_FETCH_ORDERS_CALENDAR:
            return { ...state, loading: true, error: '' };
        case FETCH_ORDERS_CALENDAR:
            return { ...state, loading: false, orders: action.payload, error: '' };
        case ERROR_FETCH_ORDERS_CALENDAR:
            return { ...state, loading: false, orders: INIT_STATE.orders, error: action.payload.message };
        
        //fetch data for fields search
        case ACTION_SEARCH_FIELDS_CALENDAR:
            return { ...state, loading: true, error: '' };
        case SEARCH_FIELDS_CALENDAR:
            return { ...state, fields: action.payload, loading: false, error: '' };
        case ERROR_SEARCH_FIELDS_CALENDAR:
            return { ...state, loading: false, fields: INIT_STATE.fields, error: action.payload.message };

        //seting list heading
        case SET_FILTER_CALENDAR:
            return { ...state, filter: action.payload };
        case CLEAR_FILTER_CALENDAR:
            return { ...state, filter: INIT_STATE.filter };
        default: return state;
    }
}

export default calendar;
