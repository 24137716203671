/**************** BUSINESS *********************/
export const ACTION_BUSINESS = "ACTION_BUSINESS";
export const CREATE_BUSINESS = "CREATE_BUSINESS";
export const ERROR_CREATE_BUSINESS = "ERROR_CREATE_BUSINESS";

export const ACTION_FETCH_BUSINESS = "ACTION_FETCH_BUSINESS";
export const FETCH_BUSINESS = "FETCH_BUSINESS";
export const ERROR_FETCH_BUSINESS = "ERROR_FETCH_BUSINESS";
export const CLEAR_BUSINESS = "CLEAR_BUSINESS";

export const UPDATE_BUSINESS = "UPDATE_BUSINESS";
export const ERROR_UPDATE_BUSINESS = "ERROR_UPDATE_BUSINESS";

export const CLEAR_ALERT_BUSINESS = "CLEAR_ALERT_BUSINESS";

export const FETCH_BUSINESSES = "FETCH_BUSINESSES";
export const ACTION_FETCH_BUSINESSES = "ACTION_FETCH_BUSINESSES";
export const ERROR_FETCH_BUSINESSES = "ERROR_FETCH_BUSINESSES";

export const DELETE_BUSINESS = "DELETE_BUSINESS";
export const ERROR_DELETE_BUSINESS = "ERROR_DELETE_BUSINESS";

export const ACTIVE_BUSINESS = "ACTIVE_BUSINESS";
export const ERROR_ACTIVE_BUSINESS = "ERROR_ACTIVE_BUSINESS";

export const ACTION_DATA_FIELDS_BUSINESS = "ACTION_DATA_FIELDS_BUSINESS";
export const DATA_FIELDS_BUSINESS = "DATA_FIELDS_BUSINESS";
export const ERROR_DATA_FIELDS_BUSINESS = "ERROR_DATA_FIELDS_BUSINESS";

export const SET_CURRENT_PAGE_BUSINESS = "SET_CURRENT_PAGE_BUSINESS";
export const SET_SORT_BUSINESS = "SET_SORT_BUSINESS";
export const SET_SELECTED_PAGE_SIZE_BUSINESS = "SET_SELECTED_PAGE_SIZE_BUSINESS";
export const SET_SEARCH_BUSINESS = "SET_SEARCH_BUSINESS";
export const SET_SEARCH_VALUE_BUSINESS = "SET_SEARCH_VALUE_BUSINESS";




