/**************** FILE *********************/
export const ACTION_LOADING_FILE = "ACTION_LOADING_FILE";
export const ACTION_LOADING_FILE_BTN = "ACTION_LOADING_FILE_BTN";

export const FILE_CHECK_TOKEN_SUCCESS = "FILE_CHECK_TOKEN_SUCCESS";
export const FILE_CHECK_TOKEN_ERROR = "FILE_CHECK_TOKEN_ERROR";

export const UNLOCK_FILES_SUCCESS = "UNLOCK_FILES_SUCCESS";
export const UNLOCK_FILES_ERROR = "UNLOCK_FILES_ERROR";

export const DELETE_ORDER_FILE = "DELETE_ORDER_FILE";
export const ERROR_DELETE_ORDER_FILE = "ERROR_DELETE_ORDER_FILE";

export const CLEAR_FILE = "CLEAR_FILE";
export const CLEAR_ALERT_FILE = "CLEAR_ALERT_FILE";
