import {
    CLEAR_GROUPAGE,
    CLEAR_ALERT_GROUPAGE,
    FETCH_GROUPAGES,
    ERROR_FETCH_GROUPAGES,
    ACTION_FETCH_GROUPAGES,
    FETCH_GROUPAGE,
    ERROR_FETCH_GROUPAGE,
    ACTION_FETCH_GROUPAGE,
    DELETE_GROUPAGE,
    ERROR_DELETE_GROUPAGE,
    DELETE_ORDER,
    ERROR_DELETE_ORDER,
    ACTION_UPDATE_GROUPAGE,
    UPDATE_GROUPAGE,
    ERROR_UPDATE_GROUPAGE,
    ACTION_ADD_ORDER_GROUPAGE,
    ADD_ORDER_GROUPAGE,
    ERROR_ADD_ORDER_GROUPAGE, 
    ACTION_PDF_ORDER_GROUPAGE,
    PDF_ORDER_GROUPAGE,
    ERROR_PDF_ORDER_GROUPAGE,
    ACTION_EMAIL_ORDER_GROUPAGE,
    EMAIL_ORDER_GROUPAGE,
    ERROR_EMAIL_ORDER_GROUPAGE,

    ACTION_DATA_FIELDS_GROUPAGE,
    DATA_FIELDS_GROUPAGE,
    ERROR_DATA_FIELDS_GROUPAGE,

    DATA_SELECT_TRANSPORTERS_GROUPAGE,
    ERROR_DATA_SELECT_TRANSPORTERS_GROUPAGE,

    SET_CURRENT_PAGE_GROUPAGE,
    SET_SORT_GROUPAGE,
    SET_SELECTED_PAGE_SIZE_GROUPAGE,
    SET_SEARCH_GROUPAGE,
    SET_SEARCH_VALUE_GROUPAGE,
    SET_FILTER_GROUPAGE,
    CLEAR_FILTER_GROUPAGE
} from './types';

const INIT_STATE = {
    groupages: {
        data: [],
        totalItemCount: 0,
        dataFields: []
    },
    groupageData: {
        id: "",
        number_id: "",
        carrier: "",
        transporter: "",
        pn_truck: "",
        pn_trailer: "",
        status: "",
        loadings: [],
        deliveries: [],
        order_ids: [],
        transporters: [],
        freight_cost_main: 0,
        transport_cost_main: 0,
        freight_cost_before: 0,
        transport_cost_before: 0,
        freight_cost_after: 0,
        transport_cost_after: 0
    },
    selectedPageSize: 10,
    search: "",
    searchValue: "",
    searchFilter: {
        order_id: '',
        customer: '',
        carrier: '',
        pn_truck: '',
        pn_trailer: '',
        loading: '',
        delivery: '',
        loading_date_from: null,
        loading_date_to: null,
        trailers: [],
        cargos: [],
        load_type: '',
        ref_number: '',
        assign_users: []
    },
    currentPage: 1,
    sortObj: null,
    updateGroupage: '',
    deleteGroupage: '',
    deleteOrder: '',
    addOrder: '',
    sendEmail: '',
    dataFields: {
        contacts: []
    },
    dataSelectTransporters: {
        transporters: []
    },
    loadingBtn: false,
    loadingBtnAddOrder: false,
    loadingBtnEmail: false, 
    loadingBtnPdf: false,
    loading: true,
    loadingModal: true,
    error: ''
};


const groupage = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_GROUPAGE:
            return { ...state, loadingBtn: false, loadingBtnAddOrder: false, loading: false, loadingModal: false, loadingBtnEmail: false, loadingBtnPdf: false, groupageData: INIT_STATE.groupageData, dataSelectTransporters: INIT_STATE.dataSelectTransporters, error: '' };
        case CLEAR_ALERT_GROUPAGE:
            return { ...state, updateGroupage: '', deleteGroupage: '', deleteOrder: '', addOrder: '', sendEmail: '', error: '' };
        case ACTION_FETCH_GROUPAGES:
            return { ...state, loading: true, error: '' };
        case FETCH_GROUPAGES:
            return { ...state, loading: false, groupages: action.payload, error: '' };
        case ERROR_FETCH_GROUPAGES:
            return { ...state, loading: false, groupages: INIT_STATE.groupages, error: action.payload.message };
        case ACTION_FETCH_GROUPAGE:
            return { ...state, loadingModal: true, error: '' };
        case FETCH_GROUPAGE:
            return { ...state, loadingModal: false, groupageData: action.payload, error: '' };
        case ERROR_FETCH_GROUPAGE:
            return { ...state, loadingModal: false, groupageData: INIT_STATE.groupageData, error: action.payload.message };
        case DELETE_GROUPAGE:
            return { ...state, loading: false, deleteGroupage: action.payload, error: '' };
        case ERROR_DELETE_GROUPAGE:
            return { ...state, loading: false, deleteGroupage: '', error: action.payload.message };
        case DELETE_ORDER:
            return { ...state, deleteOrder: action.payload, error: '' };
        case ERROR_DELETE_ORDER:
            return { ...state, deleteOrder: '', error: action.payload.message };
        case ACTION_ADD_ORDER_GROUPAGE:
            return { ...state, loadingBtnAddOrder: true, error: '' };
        case ADD_ORDER_GROUPAGE:
            return { ...state, loadingBtnAddOrder: false, addOrder: action.payload,  error: '' };
        case ERROR_ADD_ORDER_GROUPAGE:
            return { ...state, loadingBtnAddOrder: false, addOrder: '', error: action.payload.message };
        case ACTION_UPDATE_GROUPAGE:
            return { ...state, loadingBtn: true, error: '' };
        case UPDATE_GROUPAGE:
            return { ...state, loadingBtn: false, updateGroupage: action.payload,  error: '' };
        case ERROR_UPDATE_GROUPAGE:
            return { ...state, loadingBtn: false, updateGroupage: '', error: action.payload.message };

        //fetch pdf
        case ACTION_PDF_ORDER_GROUPAGE:
            return { ...state, loadingBtnPdf: true, error: '' };
        case PDF_ORDER_GROUPAGE:
            return { ...state, loadingBtnPdf: false, error: '' };
        case ERROR_PDF_ORDER_GROUPAGE:
            return { ...state, loadingBtnPdf: false, error: action.payload.message };

        //Send Email
        case ACTION_EMAIL_ORDER_GROUPAGE:
            return { ...state, loadingBtnEmail: true, error: '' };
        case EMAIL_ORDER_GROUPAGE:
            let email_order = {...state.groupageData};
            email_order.status = 2;

            return { ...state, loadingBtnEmail: false, sendEmail: action.payload, groupageData: email_order, error: '' };
        case ERROR_EMAIL_ORDER_GROUPAGE:
            return { ...state, loadingBtnEmail: false, sendEmail: '', error: action.payload.message };

  
        //fetch data for fields
        case ACTION_DATA_FIELDS_GROUPAGE:
            return { ...state, loadingModal: true, error: '' };
        case DATA_FIELDS_GROUPAGE:
            return { ...state, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_GROUPAGE:
            return { ...state, loadingModal: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };

        //fetch data select for Transporters
        case DATA_SELECT_TRANSPORTERS_GROUPAGE:
            let trdata = {...state.groupageData};
            trdata.transporter = "";

            return { ...state, dataSelectTransporters: action.payload, groupageData: trdata, error: '' };
        case ERROR_DATA_SELECT_TRANSPORTERS_GROUPAGE:
            let data = {...state.groupageData};
            data.transporter = "";

            return { ...state, loading: false, dataSelectTransporters: INIT_STATE.dataSelectTransporters, groupageData: data, error: action.payload.message };


        //seting list heading
        case SET_CURRENT_PAGE_GROUPAGE:
            return { ...state, currentPage: action.payload };
        case SET_SORT_GROUPAGE:
            return { ...state, sortObj: action.payload };
        case SET_SELECTED_PAGE_SIZE_GROUPAGE:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_SEARCH_GROUPAGE:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE_GROUPAGE:
            return { ...state, searchValue: action.payload };
        case SET_FILTER_GROUPAGE:
            return { ...state, searchFilter: action.payload };
        case CLEAR_FILTER_GROUPAGE:
            return { ...state, searchFilter: INIT_STATE.searchFilter };
        default: return state;
    }
}

export default groupage;
