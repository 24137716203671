import {
    ACTION_FIRM,
    CREATE_FIRM,
    ERROR_CREATE_FIRM,
    ACTION_FETCH_FIRM,
    FETCH_FIRM,
    ERROR_FETCH_FIRM,
    CLEAR_FIRM,
    UPDATE_FIRM,
    ERROR_UPDATE_FIRM,
    CLEAR_ALERT_FIRM,
    FETCH_FIRMS,
    ERROR_FETCH_FIRMS,
    ACTION_FETCH_FIRMS,
    DELETE_FIRM,
    ERROR_DELETE_FIRM,
    ACTIVE_FIRM,
    ERROR_ACTIVE_FIRM,
    ACTION_DATA_FIELDS_FIRM,
    DATA_FIELDS_FIRM,
    ERROR_DATA_FIELDS_FIRM,
    EMAIL_FIRM,
    ERROR_EMAIL_FIRM,
    FETCH_REST_INSURANCE,
    ERROR_FETCH_REST_INSURANCE,
    SET_SEARCH_FIRM,
    SET_SEARCH_VALUE_FIRM,
    SET_FILTER_FIRM,
    CLEAR_FILTER_FIRM,
    SET_CURRENT_PAGE_ORDER
} from './types';

const INIT_STATE = {
    firms: {
        data: [],
        totalItemCount: 0
    },
    currentPage: 1,
    search: "",
    searchValue: "",
    searchFilter: {
        assign_users: [],
        firm_types: [],
        operations: [],
        countries: [],
        cargos: [],
        trailers: [],
        address: ""
    },
    firmData: {
        business_id: 1,
        name: '',
        assignee: '',
        insurance: 0,
        company_insurance: 0,
        charged_insurance: 0,
        terms: '',
        term_type_id: '',
        firm_types: [],
        operations: [],
        loads: [],
        vat: '',
        tax: '',
        heard_about_type_id: '',
        description: '',
        trailer_number: null,
        assign_users: [],
        created_user: "",
        created_at: "",
        active: 0,
        addresses: [],
        countries: [],
        cargos: [],
        trailers: [],
        files: [],
        filesData: [],
        zoom: 4
    },
    createFirm: '',
    updateFirm: '',
    deleteFirm: '',
    activeFirm: '',
    sendEmail: '',
    dataFields: {
        countries: [],
        firms: [],
        operations: [],
        loads: [],
        heard_abouts: [],
        trailers: [],
        cargos: [],
        users: [],
        files: [],
        terms: [],
        businesses: []
    },
    loadingBtn: false,
    loadingModal: false,
    loadingTab: false,
    loading: true,
    error: ''
};


const firm = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_FIRM:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_FIRM:
            return { ...state, loadingBtn: false, createFirm: action.payload, error: '' };
        case ERROR_CREATE_FIRM:
            return { ...state, loadingBtn: false, createFirm: '', error: action.payload.message };
        case ACTION_FETCH_FIRM:
            return { ...state, loadingTab: true, error: '' };
        case FETCH_FIRM:
            return { ...state, loadingTab: false, firmData: action.payload, error: '' };
        case ERROR_FETCH_FIRM:
            return { ...state, loadingTab: false, firmData: '', error: action.payload.message };
        case CLEAR_FIRM:
            return { ...state, loadingBtn: false, loading: false, loadingTab: false, firmData: INIT_STATE.firmData, error: '' };
        case UPDATE_FIRM:
            return { ...state, loadingBtn: false, updateFirm: action.payload, error: '' };
        case ERROR_UPDATE_FIRM:
            return { ...state, loadingBtn: false, updateFirm: '', error: action.payload.message };
        case CLEAR_ALERT_FIRM:
            return { ...state, updateFirm: '', createFirm: '', deleteFirm: '', activeFirm: '', sendEmail: '', error: '' };
        case ACTION_FETCH_FIRMS:
            return { ...state, loading: true, error: '' };
        case FETCH_FIRMS:
            return { ...state, loading: false, firms: action.payload, error: '' };
        case ERROR_FETCH_FIRMS:
            return { ...state, loading: false, firms: INIT_STATE.firms, error: action.payload.message };
        case DELETE_FIRM:
            return { ...state, loadingTab: false, deleteFirm: action.payload, error: '' };
        case ERROR_DELETE_FIRM:
            return { ...state, loadingTab: false, deleteFirm: '', error: action.payload.message };
        case ACTIVE_FIRM:
            return { ...state, loadingTab: false, activeFirm: action.payload, error: '' };
        case ERROR_ACTIVE_FIRM:
            return { ...state, loadingTab: false, activeFirm: '', error: action.payload.message };
        case EMAIL_FIRM:
            return { ...state, loadingTab: false, sendEmail: action.payload, error: '' };
        case ERROR_EMAIL_FIRM:
            return { ...state, loadingTab: false, sendEmail: '', error: action.payload.message };
        
        //Get rest of insurance
        case FETCH_REST_INSURANCE:
            return { ...state, loadingInsurance: false, firmData: action.payload, error: '' };
        case ERROR_FETCH_REST_INSURANCE:
            return { ...state, loadingInsurance: false, error: action.payload.message };

        //fetch data for fields
        case ACTION_DATA_FIELDS_FIRM:
            return { ...state, loading: true, error: '' };
        case DATA_FIELDS_FIRM:
            return { ...state, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_FIRM:
            return { ...state, loading: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };

        case SET_SEARCH_FIRM:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE_FIRM:
            return { ...state, searchValue: action.payload };
        case SET_FILTER_FIRM:
            return { ...state, searchFilter: action.payload };
        case CLEAR_FILTER_FIRM:
            return { ...state, searchFilter: INIT_STATE.searchFilter };
        case SET_CURRENT_PAGE_ORDER:
            return { ...state, currentPage: action.payload };
        default: return state;
    }
}

export default firm;
