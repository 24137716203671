import React, { Component, Suspense } from "react";

import { Switch, BrowserRouter as Router, Redirect, Route } from "react-router-dom";

import { connect } from "react-redux";
import { loginAuthorError } from './redux/auth/actions';

import { LastLocationProvider } from 'react-router-last-location';
import jwt from 'jsonwebtoken';
import { jwt_secret, AUTH, USER, ACCOUNT } from './constants/defaultValues';

// layouts Format
import VerticalLayout from "./components/VerticalLayout";
import HorizontalLayout from "./components/HorizontalLayout";

// Import scss
import "./assets/scss/theme.scss";

const ViewMain = React.lazy(() =>
  import('./views')
);
const ViewApp = React.lazy(() =>
  import('./views/app')
);
const ViewUser = React.lazy(() =>
  import('./views/user')
);
const ViewFiles = React.lazy(() =>
  import('./views/files')
);
const ViewError = React.lazy(() =>
  import('./views/error')
);

const AuthRoute = ({ component: Component, authUser, layout: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
            <Redirect
              to={{
                pathname: '/user/login',
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}

const getLayout = (layoutType) => {
  let layoutCls = VerticalLayout;

  switch (layoutType) {
    case "horizontal":
      layoutCls = HorizontalLayout;
      break;
    default:
      layoutCls = VerticalLayout;
      break;
  }
  return layoutCls;
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOnline: navigator.onLine
    };
}

  componentDidMount() {
    const { author, loginAuthorError } = this.props;

    if (author) {
      let token = author.access_token;

      if (token) {
        jwt.verify(token, jwt_secret, (err, decoded) => {
          if (err) {
            loginAuthorError('Your session expired.')
          } else {
            if ((decoded.iss !== `${AUTH}/login`)
              && (decoded.iss !== `${AUTH}/refreshToken`)
              && (decoded.iss !== `${AUTH}/reset`)
              && !decoded.iss.includes(`${USER}/update/`) 
              && !decoded.iss.includes(`${ACCOUNT}/update/`)) {
              loginAuthorError('Your session expired.')
            }
          }
        });
      }
    } else {
      loginAuthorError('')
    }

    // Listen to the online status
    window.addEventListener('online', this.handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', this.handleStatusChange);
  }

  componentWillUnmount(){
    window.removeEventListener('online', this.handleStatusChange);
    window.removeEventListener('offline', this.handleStatusChange);
  }


  handleStatusChange = () => {
    this.setState({ isOnline: navigator.onLine });
  };

  render() {
    const { author } = this.props;
    const Layout = getLayout(this.props.layout.layoutType);

    return (
      <React.Fragment>
        {!this.state.isOnline ? 
          <div className="internet-error">
            <span>Internet connection lost</span>
          </div>
        : null}
        <Suspense fallback={<div className="loading-overlay" />}>
          <Router>
            <LastLocationProvider>
                <Switch>
                  <AuthRoute
                    path="/app"
                    authUser={author}
                    component={ViewApp}
                    layout={Layout}
                  />
                  <Route
                    path="/user"
                    render={props => <ViewUser  {...props} />}
                  />
                  <Route
                    path="/files"
                    render={props => <ViewFiles  {...props} />}
                  />
                  <Route
                    path="/error"
                    exact
                    render={props => <ViewError {...props} />}
                  />
                  <Route
                    path="/"
                    exact
                    render={props => <ViewMain {...props} />}
                  />
                  <Redirect to="/error" />
                </Switch>
            </LastLocationProvider>
          </Router>
        </Suspense>
      </React.Fragment>
    );
  }
};

const mapStateToProps = ({authUser, layout}) => {
  const { author } = authUser;

  return { author, layout };
};

const mapDispatchToProps = dispatch => ({
  loginAuthorError: message => dispatch(loginAuthorError(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(App);