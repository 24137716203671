import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Collapse } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"

const Navbar = props => {
  const [users, setUsers] = useState(false);
  const [accounts, setAccounts] = useState(false);
  const [places, setPlaces] = useState(false)
  const [categories, setCategories] = useState(false)

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })
  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="topnav">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                {props.author.role === 5 ? 
                  <>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/app/dashboard/map"
                      >
                        <i className="uil-home-alt me-2"></i>
                        {" "}{props.t("menu.map")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/app/calendar"
                      >
                        <i className="uil-calender me-2"></i>
                        {" "}{props.t("menu.calendar")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/app/bookings/list"
                      >
                        <i className="uil-calendar-alt me-2"></i>
                        {" "}{props.t("menu.bookings")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/app/orders/list"
                      >
                        <i className="uil-edit me-2"></i>
                        {" "}{props.t("menu.orders")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/app/stats"
                      >
                        <i className="uil-chart-bar me-2"></i>
                        {" "}{props.t("general.stats")}
                      </Link>
                    </li>
                  </>
                :
                <>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/app/dashboard/map"
                  >
                    <i className="uil-home-alt me-2"></i>
                    {" "}{props.t("menu.map")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/app/calendar"
                  >
                    <i className="uil-calender me-2"></i>
                    {" "}{props.t("menu.calendar")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/app/bookings/list"
                  >
                    <i className="uil-calendar-alt me-2"></i>
                    {" "}{props.t("menu.bookings")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/app/orders/list"
                  >
                    <i className="uil-edit me-2"></i>
                    {" "}{props.t("menu.orders")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/app/groupages/list"
                  >
                    <i className="uil-box"></i>
                    {" "}{props.t("menu.groupages")}
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setPlaces(!places)
                    }}
                    className="nav-link dropdown-togglez arrow-none"
                  >
                    <i className="uil-map-pin-alt"></i>
                    {" "}{props.t("menu.places")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: places })}>
                    <Link to="/app/places/list" className="dropdown-item">
                      {props.t("menu.places-list")}
                    </Link>
                    <Link to="/app/places/create" className="dropdown-item">
                      {props.t("menu.create-place")}
                    </Link>
                  </div>
                </li> 
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/app/firms"
                  >
                    <i className="uil-building"></i>
                    {" "}{props.t("menu.firms")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/app/groups"
                  >
                    <i className="uil-layer-group"></i>
                    {" "}{props.t("menu.groups")}
                  </Link>
                </li>
                {props.author.role === 1 || props.author.role === 2 || props.author.role === 3 ?
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setCategories(!categories)
                    }}
                    className="nav-link dropdown-togglez arrow-none"
                  >
                    <i className="uil-apps"></i>
                    {" "}{props.t("menu.categories")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: categories })}>
                    <Link to="/app/categories/list/gender" className="dropdown-item">
                      {props.t("menu.gender-list")}
                    </Link>
                    <Link to="/app/categories/list/job_title" className="dropdown-item">
                      {props.t("menu.job_title-list")}
                    </Link>
                    <Link to="/app/categories/list/country" className="dropdown-item">
                      {props.t("menu.country-list")}
                    </Link>
                    <Link to="/app/categories/list/trailer" className="dropdown-item">
                      {props.t("menu.trailer-list")}
                    </Link>
                    <Link to="/app/categories/list/packing" className="dropdown-item">
                      {props.t("menu.packing-list")}
                    </Link>
                    <Link to="/app/categories/list/cargo" className="dropdown-item">
                      {props.t("menu.cargo-list")}
                    </Link>
                    <Link to="/app/categories/list/ambient" className="dropdown-item">
                      {props.t("menu.ambient-list")}
                    </Link>
                    <Link to="/app/categories/list/firm" className="dropdown-item">
                      {props.t("menu.firm-list")}
                    </Link>
                    <Link to="/app/categories/list/operation" className="dropdown-item">
                      {props.t("menu.operation-list")}
                    </Link>
                    <Link to="/app/categories/list/load" className="dropdown-item">
                      {props.t("menu.load-list")}
                    </Link>
                    <Link to="/app/categories/list/heard_about" className="dropdown-item">
                      {props.t("menu.heard_about-list")}
                    </Link>
                    <Link to="/app/categories/list/file" className="dropdown-item">
                      {props.t("menu.file-list")}
                    </Link>
                    <Link to="/app/categories/list/log" className="dropdown-item">
                      {props.t("menu.log-list")}
                    </Link>
                    <Link to="/app/categories/list/term" className="dropdown-item">
                      {props.t("menu.term-list")}
                    </Link>
                    <Link to="/app/categories/list/cost" className="dropdown-item">
                      {props.t("menu.cost-list")}
                    </Link>
                    <Link to="/app/categories/list/reference" className="dropdown-item">
                      {props.t("menu.reference-list")}
                    </Link>
                    <Link to="/app/categories/list/damage" className="dropdown-item">
                      {props.t("menu.damage-list")}
                    </Link>
                  </div>
                </li> 
                : null}
                {props.author.role === 1 || props.author.role === 2 ? 
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setAccounts(!accounts)
                    }}
                    className="nav-link dropdown-togglez arrow-none"
                  >
                    <i className="uil-user-square"></i>
                    {" "}{props.t("menu.accounts")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: users })}>
                    <Link to="/app/accounts/list" className="dropdown-item">
                      {props.t("menu.accounts-list")}
                    </Link>
                    <Link to="/app/accounts/create" className="dropdown-item">
                      {props.t("menu.create-account")}
                    </Link>
                  </div>
                </li> 
                : null}
                {props.author.role === 1 || props.author.role === 2 ? 
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault()
                      setUsers(!users)
                    }}
                    className="nav-link dropdown-togglez arrow-none"
                  >
                    <i className="uil-users-alt"></i>
                    {" "}{props.t("menu.users")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: users })}>
                    <Link to="/app/users/list" className="dropdown-item">
                      {props.t("menu.users-list")}
                    </Link>
                    <Link to="/app/users/create" className="dropdown-item">
                      {props.t("menu.create-user")}
                    </Link>
                  </div>
                </li> 
                :null}
                </>
                }
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = ({ authUser, layout }) => {
  const { author } = authUser;
  const { leftMenu } = layout
  return { leftMenu, author }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)