import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage';


import layout from "./layout/reducer"
import authUser from './auth/reducer';
import file from './file/reducer';
import user from './users/reducer';
import account from './accounts/reducer';
import category from './categories/reducer';
import bank from './banks/reducer';
import business from './businesses/reducer';
import place from './places/reducer';
import firm from './firms/reducer';
import contact from './contacts/reducer';
import offer from './offers/reducer';
import group from './groups/reducer';
import map from './map/reducer';
import calendar from './calendar/reducer';
import order from './orders/reducer';
import groupage from './groupages/reducer';
import booking from './bookings/reducer';
import stat from './stats/reducer';
import notification from './notifications/reducer';
import chat from './chat/reducer';


const persistConfig = {
  key: 'root',
  storage,
  transform: [
    createFilter('authUser', ['author'])
  ]
}


const rootReducer = combineReducers({
  layout,
  authUser,
  file,
  user,
  account,
  category,
  bank,
  business,
  place,
  firm,
  contact,
  offer,
  group,
  calendar,
  map,
  order,
  groupage,
  booking,
  stat,
  notification,
  chat
});

export default persistReducer(persistConfig, rootReducer);