/**************** CATEGORY *********************/
export const ACTION_CATEGORY = "ACTION_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const ERROR_CREATE_CATEGORY = "ERROR_CREATE_CATEGORY";

export const ACTION_FETCH_CATEGORY = "ACTION_FETCH_CATEGORY";
export const FETCH_CATEGORY = "FETCH_CATEGORY";
export const ERROR_FETCH_CATEGORY = "ERROR_FETCH_CATEGORY";
export const CLEAR_CATEGORY = "CLEAR_CATEGORY";

export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const ERROR_UPDATE_CATEGORY = "ERROR_UPDATE_CATEGORY";

export const CLEAR_ALERT_CATEGORY = "CLEAR_ALERT_CATEGORY";

export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const ACTION_FETCH_CATEGORIES = "ACTION_FETCH_CATEGORIES";
export const ERROR_FETCH_CATEGORIES = "ERROR_FETCH_CATEGORIES";

export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const ERROR_DELETE_CATEGORY = "ERROR_DELETE_CATEGORY";

export const ACTIVE_CATEGORY = "ACTIVE_CATEGORY";
export const ERROR_ACTIVE_CATEGORY = "ERROR_ACTIVE_CATEGORY";

export const ACTION_DATA_FIELDS_CATEGORY = "ACTION_DATA_FIELDS_CATEGORY";
export const DATA_FIELDS_CATEGORY = "DATA_FIELDS_CATEGORY";
export const ERROR_DATA_FIELDS_CATEGORY = "ERROR_DATA_FIELDS_CATEGORY";

export const SET_CURRENT_PAGE_CATEGORY = "SET_CURRENT_PAGE_CATEGORY";
export const SET_SORT_CATEGORY = "SET_SORT_CATEGORY";
export const SET_SELECTED_PAGE_SIZE_CATEGORY = "SET_SELECTED_PAGE_SIZE_CATEGORY";
export const SET_SEARCH_CATEGORY = "SET_SEARCH_CATEGORY";
export const SET_SEARCH_VALUE_CATEGORY = "SET_SEARCH_VALUE_CATEGORY";




