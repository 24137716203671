import React, { Component } from "react"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Spinner } from "reactstrap"
import SimpleBar from "simplebar-react"

import ReactHtmlParser from 'react-html-parser'; 

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux";
import {
  startFetchNotifications,
  startSeenNotifications,
  startReadNotification,
  startAllReadNotifications,
  setCurrentPage,
  setSelectedPageSize,
  setNotification,
  clearNotifications
} from "../../../redux/notifications/actions";

import { startRefreshToken } from "../../../redux/auth/actions";

import jwt from 'jsonwebtoken';
import { jwt_secret, BASEURLAPIIMAGES } from '../../../constants/defaultValues';

import Pusher from 'pusher-js';

class NotificationDropdown extends Component {
  constructor(props) {
      super(props);

      this.state = {
          menu: false
      };

      this.scrollableNodeRef = React.createRef();
  }

  componentDidMount() {
    this.props.clearNotifications();
    setTimeout(() => {
      this.dataListRender();
      this.initializeNotification();
    }, 500); 
  }

  loadMore = () => {
    const { loading, setCurrentPage, currentPage } = this.props;

    const element = document.getElementById('notifications-container').querySelector('.simplebar-content');
    const container = this.scrollableNodeRef.current;

    if (((container.scrollTop + 1) === (element.clientHeight - container.clientHeight)) && !loading){
      setCurrentPage(currentPage + 1)
      
      setTimeout(() => {
        this.dataListRender();
      }, 500); 
    }  
  }

  initializeNotification(){
    const { author } = this.props;
    Pusher.logToConsole = false;

    let pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: 'eu'
    });

    let channel = pusher.subscribe('simple-message');

    const setNewNotification = this.setNewNotification;

    channel.bind('message', function(data) {
      if(data.data.users.includes(author.user.id)){
        setNewNotification(data.data);
      }
    });
  }

  setNewNotification = (data) => {
    let notifications = {...this.props.notifications};
    notifications.data.unshift(data);
    notifications.unseenCount = parseInt(notifications.unseenCount) + 1;

    this.props.setNotification(notifications);
  }

  dataListRender = () => {
      const {
          startRefreshToken,
          startFetchNotifications,
          history,
          author,
          selectedPageSize,
          currentPage,
          i18n
      } = this.props;

      const filter = {
          pageSize: selectedPageSize,
          currentPage: currentPage
      };

      jwt.verify(author.access_token, jwt_secret, (err, decoded) => {
          if (err) {
              startRefreshToken(author.access_token, history).then(res => {
                startFetchNotifications(i18n.language, res.access_token, filter);
              });
          } else {
            startFetchNotifications(i18n.language, author.access_token, filter);
          }
      });
  }

  readNotifications = (id, index, e) => {
    const {
        startRefreshToken,
        startReadNotification,
        history,
        author,
        i18n
    } = this.props;

    jwt.verify(author.access_token, jwt_secret, (err, decoded) => {
        if (err) {
            startRefreshToken(author.access_token, history).then(res => {
              startReadNotification(i18n.language, res.access_token, id, index);
            });
        } else {
          startReadNotification(i18n.language, author.access_token, id, index);
        }
    });
    // e.preventDefault();
    // e.stopPropagation();
  }

  unseenNotifications = () => {
    const {
        startRefreshToken,
        startSeenNotifications,
        history,
        author,
        i18n
    } = this.props;

    jwt.verify(author.access_token, jwt_secret, (err, decoded) => {
        if (err) {
            startRefreshToken(author.access_token, history).then(res => {
              startSeenNotifications(i18n.language, res.access_token);
            });
        } else {
          startSeenNotifications(i18n.language, author.access_token);
        }
    });
  }

  allReadNotifications = (e) => {
    const {
        startRefreshToken,
        startAllReadNotifications,
        history,
        author,
        i18n
    } = this.props;

    jwt.verify(author.access_token, jwt_secret, (err, decoded) => {
        if (err) {
            startRefreshToken(author.access_token, history).then(res => {
              startAllReadNotifications(i18n.language, res.access_token);
            });
        } else {
          startAllReadNotifications(i18n.language, author.access_token);
        }
    });
  }

  render() {
    return (
      <>
        <Dropdown
          isOpen={this.state.menu}
          toggle={() => this.setState({menu: !this.state.menu})}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon waves-effect"
            tag="button"
            id="page-header-notifications-dropdown"
            onClick={() => this.unseenNotifications()}
          >
            <i className={`uil-bell ${this.props.notifications.unseenCount > 0 ? 'text-danger' : ''}`}></i>
            {this.props.notifications.unseenCount > 0 ?
              <span className="badge bg-danger rounded-pill">{this.props.notifications.unseenCount}</span>
            : null}
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 font-size-16"> 
                    {this.props.t("Notifications")}{" "}
                    {this.props.loading ? 
                      <span style={{position: 'relative', top: -4}}>
                        <Spinner type="grow" color="primary" style={{width: '0.4rem', height: '0.4rem'}}/>  
                        <Spinner type="grow" color="primary" style={{width: '0.4rem', height: '0.4rem'}}/>
                        <Spinner type="grow" color="primary" style={{width: '0.4rem', height: '0.4rem'}}/>
                      </span>
                    :null}
                  </h6>
                </Col>
                <div className="col-auto" onClick={(e) => this.allReadNotifications(e)}>
                  <a href="#!" className="small">
                    {this.props.t('general.mark-all-read')}
                  </a>
                </div>
              </Row>
            </div>

            <SimpleBar onScrollCapture={(event) => this.loadMore()} id="notifications-container" style={{ height: "230px" }} scrollableNodeProps={{ ref: this.scrollableNodeRef }}>
              {this.props.notifications.data.map((not, i) => (
                  <Link to={""} className="text-reset notification-item" key={i} onClick={(e) => {
                      this.readNotifications(not.id, i, e)
                      window.location = not.link;
                    }}>
                    <div className="d-flex align-items-start">
                      {not.image ?
                          <img alt="user-pic" className="me-3 rounded-circle avatar-xs" src={`${BASEURLAPIIMAGES}/users/${not.from_id}/${not.image}`} />
                          :
                          <div id="profileImage" style={{marginRight: 10}}>{not.title.split(" ")[0].charAt(0).toUpperCase() + not.title.split(" ")[1].charAt(0).toUpperCase()}</div>
                      }
                      <div className="flex-1">
                        <h6 className="mt-0 mb-1">{not.title}</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">
                            {ReactHtmlParser(not.message)}
                          </p>
                          <p className="mb-0">
                            <i className="mdi mdi-clock-outline"/>
                            {not.date}{" "}
                          </p>
                        </div>
                      </div>
                      {not.read === 0 ? 
                      <i className="uil-circle text-primary"/>
                      : null}
                    </div>
                  </Link>
              ))}
            </SimpleBar>
            {/* {this.props.loading ? 
              <div className="p-2 border-top d-grid" >
                <div className="text-center">
                  <Spinner type="grow" color="primary" style={{width: '0.5rem', height: '0.5rem'}}/>  
                  <Spinner type="grow" color="primary" style={{width: '0.5rem', height: '0.5rem'}}/>
                  <Spinner type="grow" color="primary" style={{width: '0.5rem', height: '0.5rem'}}/>
                </div>
              </div>
            : null} */}
          </DropdownMenu>
        </Dropdown>
      </>
    )
  }
}

const mapStateToProps = ({ authUser, notification }) => {
  const { author } = authUser;
  const { notifications, seenNotifications, readNotification, allReadNotifications, currentPage, selectedPageSize, loading, error } = notification;
  return { notifications, seenNotifications, readNotification, allReadNotifications, currentPage, selectedPageSize, loading, error, author };
};

const mapDispatchToProps = dispatch => ({
  startFetchNotifications: (locale, token, filter) => dispatch(startFetchNotifications(locale, token, filter)),
  startSeenNotifications: (locale, token) => dispatch(startSeenNotifications(locale, token)),
  startReadNotification: (locale, token, id, index) => dispatch(startReadNotification(locale, token, id, index)),
  startAllReadNotifications: (locale, token) => dispatch(startAllReadNotifications(locale, token)),
  setCurrentPage: currentPage => dispatch(setCurrentPage(currentPage)),
  setSelectedPageSize: size => dispatch(setSelectedPageSize(size)),
  setNotification: notification => dispatch(setNotification(notification)),
  clearNotifications: () => dispatch(clearNotifications()),
  startRefreshToken: (token, history) => dispatch(startRefreshToken(token, history)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NotificationDropdown));

