import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { logout } from "../../../redux/auth/actions";
import { withRouter, Link } from "react-router-dom"

import { BASEURLAPIIMAGES } from '../../../constants/defaultValues';

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)


  const handleLogout = () => {
      const { author, logout, history } = props;
      logout(author.access_token, history);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          {props.author.user.image ?
              <img alt="Profile" className="rounded-circle header-profile-user" src={`${BASEURLAPIIMAGES}/users/${props.author.user.id}/${props.author.user.image}`} />
              :
              <div id="profileImage" className="mr-2">{props.author.user.name.charAt(0).toUpperCase() + props.author.user.surname.charAt(0).toUpperCase()}</div>
          }
          <span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{props.author.user.name}</span>{" "}
          <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem header>{props.t("general.profile")}</DropdownItem>
          {props.author.role === 5 ? 
          <DropdownItem tag="a" href={`/app/accounts/edit/${props.author.user.id}`}>
            {" "}
            <i className="uil-edit-alt font-size-18 align-middle text-muted me-1"></i>
            {props.t("edit-profile")}{" "}
          </DropdownItem>
          : 
          <>
            <DropdownItem tag="a" href={`/app/users/edit/${props.author.user.id}`}>
              {" "}
              <i className="uil-edit-alt font-size-18 align-middle text-muted me-1"></i>
              {props.t("edit-profile")}{" "}
            </DropdownItem>
            <div className="dropdown-divider" />
            <DropdownItem header>{props.t("general.operations")}</DropdownItem>
            {props.author.role === 1 || props.author.role === 2 || props.author.role === 3 ? 
            <DropdownItem tag="a" href={`/app/businesses/list`}>
              {" "}
              <i className="uil-home-alt font-size-18 align-middle text-muted me-1"></i>
              {props.t("general.business")}{" "}
            </DropdownItem>
            : null}
            {props.author.role === 1 || props.author.role === 2 || props.author.role === 3 ? 
            <DropdownItem tag="a" href={`/app/banks/list`}>
              {" "}
              <i className="uil-card-atm font-size-18 align-middle text-muted me-1"></i>
              {props.t("general.bank-accounts")}{" "}
            </DropdownItem>
            : null}
          </>
          }
          
          {props.author.role === 1 || props.author.role === 3 ? 
            <DropdownItem tag="a" href={`/app/stats`}>
              {" "}
              <i className="uil-chart-bar font-size-18 align-middle text-muted me-1"></i>
              {props.t("general.stats")}{" "}
            </DropdownItem>
          : null}
          <div className="dropdown-divider" />
          <Link to="#!" className="dropdown-item" onClick={() => handleLogout()}>
            <i className="uil uil-sign-out-alt font-size-18 align-middle me-1 text-muted"></i>
            <span>{props.t("general.logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

const mapStatetoProps = ({authUser}) => {
  const { author } = authUser;
  return { author }
}

const mapDispatchToProps = dispatch => ({
  logout: token => dispatch(logout(token))
})

export default withRouter(
  connect(mapStatetoProps, mapDispatchToProps)(withTranslation()(ProfileMenu))
)
