import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  SHOW_SIDEBAR,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
} from "./types"

import { store } from '../store';


/**
 * Toggle the class on body
 */
 function manageBodyClass(cssClass, action = "toggle") {
  switch (action) {
    case "add":
      if (document.body) document.body.classList.add(cssClass);
      break;
    case "remove":
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}

/**
 * Changes the layout type
 */
 function changeLayoutcss(layout) {
    if (layout === "horizontal") {
      store.dispatch(changeTopbarTheme("colored"));
      document.body.removeAttribute("data-sidebar");
      document.body.removeAttribute("data-sidebar-size");
    } else {
      store.dispatch(changeTopbarTheme("light"));
    }
    document.body.setAttribute("data-layout", layout);
}

/**
 * Changes the layout width
 */
 function changeLayoutWidthcss(width) {
    if (width === "boxed") {
      store.dispatch(changeSidebarType("icon"));
      if (document.body){ 
        document.body.setAttribute("data-layout-size", width);
        document.body.setAttribute("data-layout-scrollable", false);
      }
    } else if (width === "scrollable") {
      store.dispatch(changeSidebarType("default"));
      if (document.body) document.body.setAttribute("data-layout-scrollable", true);
    } else {
      store.dispatch(changeSidebarType("default"));
      if (document.body){ 
        document.body.setAttribute("data-layout-size", width);
        document.body.setAttribute("data-layout-scrollable", false);
      }
    }
}

/**
 * Changes the left sidebar type
 */
function changeLeftSidebarType(sidebarType, isMobile) {
  try {
    switch (sidebarType) {
      case "compact":
        if (document.body) document.body.setAttribute("data-sidebar-size", "small");
          manageBodyClass("sidebar-enable", "remove");
          manageBodyClass("vertical-collpsed", "remove");
        break;
      case "icon":
        manageBodyClass("vertical-collpsed", "add");
        manageBodyClass("right-bar-enabled", "add");
        if (document.body) document.body.setAttribute("data-keep-enlarged", "true");
        break;
      case "condensed":
        manageBodyClass("sidebar-enable", "add");
        if (window.screen.width >= 992) {
          manageBodyClass("sidebar-enable", "remove");
          manageBodyClass("sidebar-enable", "add");
        } else {
          manageBodyClass("sidebar-enable", "add");
        }
        break;
      default:
        if (document.body) document.body.setAttribute("data-sidebar-size", "");
        manageBodyClass("sidebar-enable", "remove");
        manageBodyClass("vertical-collpsed", "remove");
        manageBodyClass("right-bar-enabled", "add");
        if (!isMobile)
          break;
    }
  } catch (error) { }
}

/***************** END OF FUNCTIONS ************************/

export const changeLayout = layout => {
  changeLayoutcss(layout);
  return{
  type: CHANGE_LAYOUT,
  payload: layout,
}}

export const changePreloader = layout => ({
  type: CHANGE_PRELOADER,
  payload: layout,
})

export const changeLayoutWidth = width => {
  changeLayoutWidthcss(width);  
  return{
  type: CHANGE_LAYOUT_WIDTH,
  payload: width,
}}

export const changeSidebarTheme = theme => {
  document.body.setAttribute("data-sidebar", theme);
  return{
  type: CHANGE_SIDEBAR_THEME,
  payload: theme,
}}

export const changeSidebarType = (sidebarType, isMobile) => {
  changeLeftSidebarType(sidebarType, isMobile)
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  }
}

export const changeTopbarTheme = topbarTheme => {
  document.body.setAttribute("data-topbar", topbarTheme)
  return {
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
}}

export const showRightSidebarAction = isopen => {
  manageBodyClass("right-bar-enabled", "add");
  return {
  type: SHOW_RIGHT_SIDEBAR,
  payload: isopen,
}}

export const showSidebar = isopen => ({
  type: SHOW_SIDEBAR,
  payload: isopen,
})

export const toggleLeftmenu = isopen => ({
  type: TOGGLE_LEFTMENU,
  payload: isopen,
})
