import {
    ACTION_SEARCH_STATS,
    SEARCH_STATS,
    ERROR_SEARCH_STATS,

    ACTION_SEARCH_PROFIT_RATES,
    SEARCH_PROFIT_RATES,
    ERROR_SEARCH_PROFIT_RATES,

    ACTION_FIRMS_STATS,
    FIRMS_STATS,
    ERROR_FIRMS_STATS,

    ACTION_DATA_FIELDS_STATS,
    DATA_FIELDS_STATS,
    ERROR_DATA_FIELDS_STATS,
    CLEAR_STATS,
    CLEAR_SEARCH,
    CLEAR_SEARCH_PROFIT_RATES,
    CLEAR_FIRMS_STATS,
    CLEAR_ALERT_STATS
} from './types';

const INIT_STATE = {
    stats: {},
    profitRates: {},
    profitRatesData: {
        expenses: '',
        date_from: '',
        date_to: ''
    },
    statsData: {
        firms: [],
        firm_type: 1,
        type: 1,
        date_from: '',
        date_to: '',
        users: [],
        country_from: '',
        country_to: '',
        operations: [],
        trailers: [],
        cargos: [],
        loads: [],
        business: [],
        status: [],
        complete_damage: '',
        damages: []
    },
    dataFields: {
        firms_customers: [],
        firms_carriers: [],
        users: [],
        countries: [],
        operations: [],
        trailers: [],
        cargos: [],
        loads: [],
        businesses: [],
        status: [],
        damages: [],
        complete_damages: []
    },
    firmsStats: [],
    loadingModal: false,
    loadingBtn: false,
    loadingProfitBtn: false,
    loading: false,
    error: ''
};


const stat = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_SEARCH_STATS:
            return { ...state, loadingBtn: true, error: '' };
        case SEARCH_STATS:
            return { ...state, loadingBtn: false, stats: action.payload, error: '' };
        case ERROR_SEARCH_STATS:
            return { ...state, loadingBtn: false, stats: INIT_STATE.stats, error: action.payload.message };

        case ACTION_SEARCH_PROFIT_RATES:
            return { ...state, loadingProfitBtn: true, error: '' };
        case SEARCH_PROFIT_RATES:
            return { ...state, loadingProfitBtn: false, profitRates: action.payload, error: '' };
        case ERROR_SEARCH_PROFIT_RATES:
            return { ...state, loadingProfitBtn: false, profitRates: INIT_STATE.profitRates, error: action.payload.message };

        case ACTION_FIRMS_STATS:
            return { ...state, loadingModal: true, error: '' };
        case FIRMS_STATS:
            return { ...state, loadingModal: false, firmsStats: action.payload, error: '' };
        case ERROR_FIRMS_STATS:
            return { ...state, loadingModal: false, firmsStats: INIT_STATE.firmsStats, error: action.payload.message };

        case CLEAR_STATS:
            return { ...state, ...INIT_STATE };
        case CLEAR_SEARCH:
            return { ...state, stats: INIT_STATE.stats };
        case CLEAR_SEARCH_PROFIT_RATES:
            return { ...state, profitRates: INIT_STATE.profitRates };
        case CLEAR_FIRMS_STATS:
            return { ...state, firmsStats: INIT_STATE.profitRates };
        case CLEAR_ALERT_STATS:
            return { ...state, error: '' };

        //fetch data for fields
        case ACTION_DATA_FIELDS_STATS:
            return { ...state, loading: true, error: '' };
        case DATA_FIELDS_STATS:
            return { ...state, loading: false, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_STATS:
            return { ...state, loading: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };

        default: return state;
    }
}

export default stat;
