
export const mapOrder = (array, order, key) => {
  array.sort(function (a, b) {
    var A = a[key], B = b[key];
    if (order.indexOf(A + "") > order.indexOf(B + "")) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};


export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!

  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return dd + '.' + mm + '.' + yyyy;
}

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ":" + now.getMinutes()
}

export const setErrorAction = errors => {
  let errorValue = "";

  if (typeof errors === 'string' || errors instanceof String) {
    errorValue = errors.result;
  } else {
    for (const prop in errors) {
      if (errors.hasOwnProperty(prop)) {
        errorValue += errors[prop];
      }
    }
  }

  return errorValue;
};


export const getFormattedPhoneNumber = phone => {
  if (phone) {
    let codeAndPhone = phone.split(" ");

    return codeAndPhone[0].replace("00", "+") + ' ' + codeAndPhone[1];
  }
};

export const getFormatedID = id => {
  let newID = String(id);
  if (newID) {
    newID = newID.replace(/\d+/g, (m) => {
      return "0000".substr(m.length) + m;
    });

    return newID
  }
};




