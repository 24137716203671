import axios from 'axios';

import { AUTH } from '../../constants/defaultValues';

import {
  ACTION_AUTHOR,
  LOGIN_AUTHOR_SUCCESS,
  LOGIN_AUTHOR_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  CHECK_RESET_TOKEN_SUCCESS,
  CHECK_RESET_TOKEN_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_ERROR,
  LOGOUT_AUTHOR
} from './types';

import { HEADERS } from '../../constants/defaultValues';

/****************************    START ACTION AUTHOR   ******************************************/

export const actionAuthor = (author, history) => ({
  type: ACTION_AUTHOR,
  payload: { author, history }
});

/****************************    END ACTION AUTHOR   ******************************************/

/****************************    START LOGIN AUTHOR   ******************************************/

export const loginAuthorSuccess = (author) => ({
  type: LOGIN_AUTHOR_SUCCESS,
  payload: author
});
export const loginAuthorError = (message) => ({
  type: LOGIN_AUTHOR_ERROR,
  payload: { message }
});

export const startLogin = (author, history) => {
  const headers = HEADERS;
  return dispatch => {
    dispatch(actionAuthor(author, history))
    headers['X-localization'] = 'en';
    axios.post(`${AUTH}/login`, author, { headers })
      .then(res => {
        dispatch(loginAuthorSuccess(res.data))
        history.push('/');
      })
      .catch(error => {
        try {
          dispatch(loginAuthorError(error.response.data.errors.result))
        } catch (error) {
          dispatch(loginAuthorError('Something went wrong. Please try again later.'))
        }
      });
  }
};

/****************************    END LOGIN AUTHOR   ******************************************/


/****************************    START LOGOUT AUTHOR   ******************************************/

export const logout = (token, history) => {
  return dispatch => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.post(`${AUTH}/logout`, null, { HEADERS });

    dispatch(logoutAuthor(history))
  }
};

export const logoutAuthor = (history) => ({
  type: LOGOUT_AUTHOR,
  payload: { history }
});

/****************************    END LOGOUT AUTHOR   ******************************************/

/****************************    START FORGOT PASSWORD   ******************************************/

export const startForgotPassword = (data, history) => {
  return dispatch => {
    dispatch(actionAuthor(data, history))
    return axios.post(`${AUTH}/forgot`, data, { HEADERS })
      .then(res => {
        data.email = '';
        dispatch(forgotPasswordSuccess(res.data.message))
      })
      .catch(error => {
        data.email = '';
        try {
          dispatch(loginAuthorError(error.response.data.errors.result))
        } catch (error) {
          dispatch(loginAuthorError('Something went wrong. Please try again later.'))
        }
      });
  }
};

export const forgotPasswordSuccess = (forgotAuthorMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotAuthorMail
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message }
});

/****************************    END FORGOT PASSWORD   ******************************************/

/****************************    START CHECK RESET TOKEN   ******************************************/

export const startCheckResetToken = (data, history) => {
  return dispatch => {
    dispatch(actionAuthor(data, history))
    axios.post(`${AUTH}/resetCheck`, data, { HEADERS })
      .then(res => {
        dispatch(checkResetTokenSuccess(res.data))
      }).catch(error => {
        try {
          dispatch(checkResetTokenError(error.response.data.errors.result))
        } catch (error) {
          dispatch(loginAuthorError('Something went wrong. Please try again later.'))
        }

        history.push('/');
      })
  }
};

export const checkResetTokenSuccess = (data) => ({
  type: CHECK_RESET_TOKEN_SUCCESS,
  payload: data
});
export const checkResetTokenError = (message) => ({
  type: CHECK_RESET_TOKEN_ERROR,
  payload: { message }
});

/****************************    END CHECK RESET TOKEN   ******************************************/

/****************************    START RESET PASSWORD   ******************************************/

export const startResetPassword = (data, history) => {
  return dispatch => {
    dispatch(actionAuthor(data, history))
    axios.post(`${AUTH}/reset`, data, { HEADERS })
      .then(res => {
        dispatch(loginAuthorSuccess(res.data))
        history.push('/');
      })
      .catch(error => {
        try {
          dispatch(resetPasswordError(error.response.data.errors.result))
        } catch (error) {
          dispatch(loginAuthorError('Something went wrong. Please try again later.'))
        }
      });
  }
};

export const resetPasswordSuccess = (newPassword) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: newPassword
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message }
});

/****************************    END RESET PASSWORD   ******************************************/

/****************************    START SET PASSWORD   ******************************************/

export const startSetPassword = (user_id, expires, signature, data, history) => {
  return dispatch => {
    dispatch(actionAuthor(data, history))
    axios.post(`${AUTH}/savePassword/${user_id}?expires=${expires}&signature=${signature}`, data, { HEADERS })
      .then(res => {
        dispatch(loginAuthorSuccess(res.data))
        history.push('/');
      })
      .catch(error => {
        try {
          dispatch(resetPasswordError(error.response.data.message))
        } catch (error) {
          dispatch(loginAuthorError('Something went wrong. Please try again later.'))
        }
      });
  }
};

export const setPasswordSuccess = (password) => ({
  type: SET_PASSWORD_SUCCESS,
  payload: password
});
export const setPasswordError = (message) => ({
  type: SET_PASSWORD_ERROR,
  payload: { message }
});

/****************************    END SET PASSWORD   ******************************************/

/****************************    START REFRESH TOKEN   ******************************************/
export const refreshTokenSuccess = (refresh) => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: refresh
});
export const refreshTokenError = (message) => ({
  type: REFRESH_TOKEN_ERROR,
  payload: { message }
});

export const startRefreshToken = (token, history) => {
  const headers = HEADERS;
  return async dispatch => {
    headers['Authorization'] = `Bearer ${token}`;
    const data = await axios.get(`${AUTH}/refreshToken`, { headers });
    try {
      dispatch(refreshTokenSuccess(data.data))
      return data.data;
    } catch (error) {
      dispatch(refreshTokenError('Token Error.'))
      history.push('/');
      return false;
    };
  }
};
/****************************    END REFRESH TOKEN   ******************************************/






