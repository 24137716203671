/* 
JWT Auth Token for connected to the api
*/
export const jwt_secret = process.env.REACT_APP_JWT;

/* 
GoogleMaps Api Key
*/
export const googleMap_secret = process.env.REACT_APP_GOOGLE_KEY;

/* 
Base urls for api and sites
*/
export const BASEURLAPI = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BASEURLAPI : process.env.REACT_APP_BASEURLAPI;
export const BASEURLMYDS = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BASEURLMYDS : process.env.REACT_APP_BASEURLMYDS;
export const BASEURLAPIIMAGES = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_BASEURLAPIIMAGES : process.env.REACT_APP_BASEURLAPIIMAGES;

//AUTH URL
export const AUTH = `${BASEURLAPI}/${process.env.REACT_APP_AUTH}`;

//FILE URL
export const FILE = `${BASEURLAPI}/${process.env.REACT_APP_FILE}`;

//USER URL
export const USER = `${BASEURLAPI}/${process.env.REACT_APP_USER}`;

//ACCOUNT URL
export const ACCOUNT = `${BASEURLAPI}/${process.env.REACT_APP_ACCOUNT}`;

//BUSINESS URL
export const BUSINESS = `${BASEURLAPI}/${process.env.REACT_APP_BUSINESS}`;

//BANK URL
export const BANK = `${BASEURLAPI}/${process.env.REACT_APP_BANK}`;

//STATS URL
export const STATS = `${BASEURLAPI}/${process.env.REACT_APP_STATS}`;

//PLACE URL
export const PLACE = `${BASEURLAPI}/${process.env.REACT_APP_PLACE}`;

//FIRM URL
export const FIRM = `${BASEURLAPI}/${process.env.REACT_APP_FIRM}`;

//CONTACT URL
export const CONTACT = `${BASEURLAPI}/${process.env.REACT_APP_CONTACT}`;

//OFFER URL
export const OFFER = `${BASEURLAPI}/${process.env.REACT_APP_OFFER}`;

//GROUP URL
export const GROUP = `${BASEURLAPI}/${process.env.REACT_APP_GROUP}`;

//CALENDAR URL
export const CALENDAR = `${BASEURLAPI}/${process.env.REACT_APP_CALENDAR}`;

//MAP URL
export const MAP = `${BASEURLAPI}/${process.env.REACT_APP_MAP}`;

//ORDER URL
export const ORDER = `${BASEURLAPI}/${process.env.REACT_APP_ORDER}`;

//GROUPAGE URL
export const GROUPAGE = `${BASEURLAPI}/${process.env.REACT_APP_GROUPAGE}`;

//BOOKING URL
export const BOOKING = `${BASEURLAPI}/${process.env.REACT_APP_BOOKING}`;

/************** START CATEGORIES URLS ******************/

// CATEGORIES URL
export const CATEGORY = `${BASEURLAPI}/${process.env.REACT_APP_CATEGORY}`;

/************** END CATEGORIES URLS ******************/

// NOTIFICATIONS URL
export const NOTIFICATION = `${BASEURLAPI}/${process.env.REACT_APP_NOTIFICATION}`;

// CHAT URL
export const CHAT = `${BASEURLAPI}/${process.env.REACT_APP_CHAT}`;


//Headers for applications requests
export const HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Accept':'application/json',
};

