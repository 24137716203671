/**************** ORDERS *********************/
export const ACTION_ORDER = "ACTION_ORDER";
export const CREATE_ORDER = "CREATE_ORDER";
export const ERROR_CREATE_ORDER = "ERROR_CREATE_ORDER";

export const ACTION_FETCH_ORDER = "ACTION_FETCH_ORDER";
export const FETCH_ORDER = "FETCH_ORDER";
export const ERROR_FETCH_ORDER = "ERROR_FETCH_ORDER";
export const CLEAR_ORDER = "CLEAR_ORDER";
export const CLEAR_ORDERS = "CLEAR_ORDERS";

export const FETCH_INFO = "FETCH_INFO";
export const ERROR_FETCH_INFO = "ERROR_FETCH_INFO";

export const UPDATE_ORDER = "UPDATE_ORDER";
export const ERROR_UPDATE_ORDER = "ERROR_UPDATE_ORDER";

export const CLEAR_ALERT_ORDER = "CLEAR_ALERT_ORDER";

export const FETCH_ORDERS = "FETCH_ORDERS";
export const ACTION_FETCH_ORDERS = "ACTION_FETCH_ORDERS";
export const ERROR_FETCH_ORDERS = "ERROR_FETCH_ORDERS";

export const DELETE_ORDER = "DELETE_ORDER";
export const ERROR_DELETE_ORDER = "ERROR_DELETE_ORDER";

export const COPY_ORDER = "COPY_ORDER";
export const ERROR_COPY_ORDER = "ERROR_COPY_ORDER";

export const STATE_ORDER = "STATE_ORDER";
export const ERROR_STATE_ORDER = "ERROR_STATE_ORDER";

export const CLOSE_ORDER = "CLOSE_ORDER";
export const ERROR_CLOSE_ORDER = "ERROR_CLOSE_ORDER";

export const ACTION_DATA_FIELDS_ORDER = "ACTION_DATA_FIELDS_ORDER";
export const DATA_FIELDS_ORDER = "DATA_FIELDS_ORDER";
export const ERROR_DATA_FIELDS_ORDER = "ERROR_DATA_FIELDS_ORDER";

export const DATA_SELECT_CUSTOMERS_ORDER = "DATA_SELECT_CUSTOMERS_ORDER";
export const ERROR_DATA_SELECT_CUSTOMERS_ORDER = "ERROR_DATA_SELECT_CUSTOMERS_ORDER";

export const DATA_SELECT_TRANSPORTERS_ORDER = "DATA_SELECT_TRANSPORTERS_ORDER";
export const ERROR_DATA_SELECT_TRANSPORTERS_ORDER = "ERROR_DATA_SELECT_TRANSPORTERS_ORDER";

export const DELETE_ORDER_ATTACH = "DELETE_ORDER_ATTACH";
export const ERROR_DELETE_ORDER_ATTACH = "ERROR_DELETE_ORDER_ATTACH";

export const ADD_LOG_ORDER = "ADD_LOG_ORDER";
export const ERROR_ADD_LOG_ORDER = "ERROR_ADD_LOG_ORDER";

export const DELETE_LOG_ORDER = "DELETE_LOG_ORDER";
export const ERROR_DELETE_LOG_ORDER = "ERROR_DELETE_LOG_ORDER";

export const EDIT_LOG_ORDER = "EDIT_LOG_ORDER";
export const ERROR_EDIT_LOG_ORDER = "ERROR_EDIT_LOG_ORDER";

export const ACTION_PDF_ORDER = "ACTION_PDF_ORDER";
export const PDF_ORDER = "PDF_ORDER";
export const ERROR_PDF_ORDER = "ERROR_PDF_ORDER";

export const ACTION_EMAIL_ORDER = "ACTION_EMAIL_ORDER";
export const EMAIL_ORDER = "EMAIL_ORDER";
export const ERROR_EMAIL_ORDER = "ERROR_EMAIL_ORDER";

export const EMAIL_PLATE_ORDER = "EMAIL_PLATE_ORDER";
export const ERROR_EMAIL_PLATE_ORDER = "ERROR_EMAIL_PLATE_ORDER";

export const ACTION_CANCELLED_ORDER = "ACTION_CANCELLED_ORDER";
export const CANCELLED_ORDER = "CANCELLED_ORDER";
export const ERROR_CANCELLED_ORDER = "ERROR_CANCELLED_ORDER";

export const ACTION_DAMAGE_ORDER = "ACTION_DAMAGE_ORDER";
export const DAMAGE_ORDER = "DAMAGE_ORDER";
export const ERROR_DAMAGE_ORDER = "ERROR_DAMAGE_ORDER";

export const FETCH_ORDERS_STATS = "FETCH_ORDERS_STATS";  
export const ERROR_FETCH_ORDERS_STATS = "ERROR_FETCH_ORDERS_STATS";

export const FETCH_FILES_CODE = "FETCH_FILES_CODE";
export const ERROR_FETCH_FILES_CODE = "ERROR_FETCH_FILES_CODE";

export const FILES_NEW_CODE = "FILES_NEW_CODE";
export const ERROR_FILES_NEW_CODE = "FILES_NEW_CODE";

export const GPS_LINK = "GPS_LINK";
export const ERROR_GPS_LINK = "ERROR_GPS_LINK";

export const HIDE_FILE_ORDER = "HIDE_FILE_ORDER";
export const ERROR_HIDE_FILE_ORDER = "ERROR_HIDE_FILE_ORDER";

export const FETCH_GROUPAGE = "FETCH_GROUPAGE";
export const ERROR_FETCH_GROUPAGE = "ERROR_FETCH_GROUPAGE";

export const ACTION_CREATE_GROUPAGE = "ACTION_CREATE_GROUPAGE";
export const CREATE_GROUPAGE = "CREATE_GROUPAGE";
export const ERROR_CREATE_GROUPAGE = "ERROR_CREATE_GROUPAGE";

export const SET_CURRENT_PAGE_ORDER = "SET_CURRENT_PAGE_ORDER";
export const SET_SORT_ORDER = "SET_SORT_ORDER";
export const SET_SELECTED_PAGE_SIZE_ORDER = "SET_SELECTED_PAGE_SIZE_ORDER";
export const SET_SEARCH_ORDER = "SET_SEARCH_ORDER";
export const SET_SEARCH_VALUE_ORDER = "SET_SEARCH_VALUE_ORDER";
export const SET_FILTER_ORDER = "SET_FILTER_ORDER";
export const CLEAR_FILTER_ORDER = "CLEAR_FILTER_ORDER";




