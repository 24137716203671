/**************** GROUPAGES *********************/
export const CLEAR_GROUPAGE = "CLEAR_GROUPAGE";

export const CLEAR_ALERT_GROUPAGE = "CLEAR_ALERT_GROUPAGE";

export const FETCH_GROUPAGES = "FETCH_GROUPAGES";
export const ACTION_FETCH_GROUPAGES = "ACTION_FETCH_GROUPAGES";
export const ERROR_FETCH_GROUPAGES = "ERROR_FETCH_GROUPAGES";

export const FETCH_GROUPAGE = "FETCH_GROUPAGE";
export const ACTION_FETCH_GROUPAGE = "ACTION_FETCH_GROUPAGE";
export const ERROR_FETCH_GROUPAGE = "ERROR_FETCH_GROUPAGE";


export const DELETE_GROUPAGE = "DELETE_GROUPAGE";
export const ERROR_DELETE_GROUPAGE = "ERROR_DELETE_GROUPAGE";

export const DELETE_ORDER = "DELETE_GROUPAGE_ORDER";
export const ERROR_DELETE_ORDER = "ERROR_DELETE_GROUPAGE_ORDER";

export const ACTION_UPDATE_GROUPAGE = "ACTION_UPDATE_GROUPAGE";
export const UPDATE_GROUPAGE = "UPDATE_GROUPAGE";
export const ERROR_UPDATE_GROUPAGE = "ERROR_UPDATE_GROUPAGE";

export const ACTION_ADD_ORDER_GROUPAGE = "ACTION_ADD_ORDER_GROUPAGE";
export const ADD_ORDER_GROUPAGE = "ADD_ORDER_GROUPAGE";
export const ERROR_ADD_ORDER_GROUPAGE = "ERROR_ADD_ORDER_GROUPAGE";

export const ACTION_PDF_ORDER_GROUPAGE = "ACTION_PDF_ORDER_GROUPAGE";
export const PDF_ORDER_GROUPAGE = "PDF_ORDER_GROUPAGE";
export const ERROR_PDF_ORDER_GROUPAGE = "ERROR_PDF_ORDER_GROUPAGE";

export const ACTION_EMAIL_ORDER_GROUPAGE = "ACTION_EMAIL_ORDER_GROUPAGE";
export const EMAIL_ORDER_GROUPAGE = "EMAIL_ORDER_GROUPAGE";
export const ERROR_EMAIL_ORDER_GROUPAGE = "ERROR_EMAIL_ORDER_GROUPAGE";

export const ACTION_DATA_FIELDS_GROUPAGE = "ACTION_DATA_FIELDS_GROUPAGE";
export const DATA_FIELDS_GROUPAGE = "DATA_FIELDS_GROUPAGE";
export const ERROR_DATA_FIELDS_GROUPAGE = "ERROR_DATA_FIELDS_GROUPAGE";

export const DATA_SELECT_TRANSPORTERS_GROUPAGE = "DATA_SELECT_TRANSPORTERS_GROUPAGE";
export const ERROR_DATA_SELECT_TRANSPORTERS_GROUPAGE = "ERROR_DATA_SELECT_TRANSPORTERS_GROUPAGE";

export const DELETE_GROUPAGE_ATTACH = "DELETE_GROUPAGE_ATTACH";
export const ERROR_DELETE_GROUPAGE_ATTACH = "ERROR_DELETE_GROUPAGE_ATTACH";

export const SET_CURRENT_PAGE_GROUPAGE = "SET_CURRENT_PAGE_GROUPAGE";
export const SET_SORT_GROUPAGE = "SET_SORT_GROUPAGE";
export const SET_SELECTED_PAGE_SIZE_GROUPAGE = "SET_SELECTED_PAGE_SIZE_GROUPAGE";
export const SET_SEARCH_GROUPAGE = "SET_SEARCH_GROUPAGE";
export const SET_SEARCH_VALUE_GROUPAGE = "SET_SEARCH_VALUE_GROUPAGE";
export const SET_FILTER_GROUPAGE = "SET_FILTER_GROUPAGE";
export const CLEAR_FILTER_GROUPAGE = "CLEAR_FILTER_GROUPAGE";




