import {
    ACTION_ORDER,
    CREATE_ORDER,
    ERROR_CREATE_ORDER,
    ACTION_FETCH_ORDER,
    FETCH_ORDER,
    ERROR_FETCH_ORDER,
    FETCH_INFO,
    ERROR_FETCH_INFO,
    CLEAR_ORDER,
    CLEAR_ORDERS,
    UPDATE_ORDER,
    ERROR_UPDATE_ORDER,
    CLEAR_ALERT_ORDER,
    FETCH_ORDERS,
    ERROR_FETCH_ORDERS,
    ACTION_FETCH_ORDERS,
    DELETE_ORDER,
    ERROR_DELETE_ORDER,
    COPY_ORDER,
    ERROR_COPY_ORDER,
    STATE_ORDER,
    ERROR_STATE_ORDER,
    CLOSE_ORDER,
    ERROR_CLOSE_ORDER,
    ACTION_DATA_FIELDS_ORDER,
    DATA_FIELDS_ORDER,
    ERROR_DATA_FIELDS_ORDER,
    DATA_SELECT_CUSTOMERS_ORDER,
    ERROR_DATA_SELECT_CUSTOMERS_ORDER,
    DATA_SELECT_TRANSPORTERS_ORDER,
    ERROR_DATA_SELECT_TRANSPORTERS_ORDER,
    DELETE_ORDER_ATTACH,
    ERROR_DELETE_ORDER_ATTACH,
    ADD_LOG_ORDER,
    ERROR_ADD_LOG_ORDER,
    DELETE_LOG_ORDER,
    ERROR_DELETE_LOG_ORDER,
    EDIT_LOG_ORDER,
    ERROR_EDIT_LOG_ORDER,
    ACTION_EMAIL_ORDER,
    EMAIL_ORDER,
    ERROR_EMAIL_ORDER,
    EMAIL_PLATE_ORDER,
    ERROR_EMAIL_PLATE_ORDER,
    ACTION_PDF_ORDER,
    PDF_ORDER,
    ERROR_PDF_ORDER,
    ACTION_CANCELLED_ORDER,  
    CANCELLED_ORDER,
    ERROR_CANCELLED_ORDER,
    ACTION_DAMAGE_ORDER,
    DAMAGE_ORDER,
    ERROR_DAMAGE_ORDER,
    FETCH_ORDERS_STATS,
    ERROR_FETCH_ORDERS_STATS,
    FETCH_FILES_CODE,
    ERROR_FETCH_FILES_CODE,
    FILES_NEW_CODE,
    ERROR_FILES_NEW_CODE,
    GPS_LINK,
    ERROR_GPS_LINK,
    HIDE_FILE_ORDER,
    ERROR_HIDE_FILE_ORDER,
    FETCH_GROUPAGE,
    ERROR_FETCH_GROUPAGE,
    ACTION_CREATE_GROUPAGE,
    CREATE_GROUPAGE,
    ERROR_CREATE_GROUPAGE,
    SET_CURRENT_PAGE_ORDER,
    SET_SORT_ORDER,
    SET_SELECTED_PAGE_SIZE_ORDER,
    SET_SEARCH_ORDER,
    SET_SEARCH_VALUE_ORDER,
    SET_FILTER_ORDER,
    CLEAR_FILTER_ORDER
} from './types';

const INIT_STATE = {
    orders: {
        data: [],
        totalItemCount: 0,
        dataFields: []
    },
    selectedPageSize: 10,
    search: "",
    searchValue: "",
    searchFilter: {
        business_id: '',
        order_id: '',
        customer: '',
        carrier: '',
        pn_truck: '',
        pn_trailer: '',
        load_option: 1,
        loading: '',
        del_option: 1,
        delivery: '',
        loading_date_from: null,
        loading_date_to: null,
        delivery_date: null,
        trailers: [],
        cargos: [],
        load_type: '',
        status: '',
        operations: [],
        ref_number: '',
        delay: '',
        assign_users: []
    },
    currentPage: 1,
    sortObj: null,
    orderData: {
        number_id: '',
        business_id: 1,
        import_export: 1,
        firm_company_id: '',
        firm_address_id: '',
        assign_user: '',
        customer_id: '',
        loading: '',
        order_details: [{
            l_date: null,
            l_time: null,
            l_place_id: '',
            l_custom: 0,
            l_custom_place_id: '',
            l_notes: '',
            l_places: [],
            l_loading: false,
            u_date: null,
            u_time: null,
            u_place_id: '',
            u_custom: 0,
            u_custom_place_id: '',
            u_notes: '',
            u_places: [],
            u_loading: false,
            trailer_type_id: '',
            load_type_id: 1,
            load_meters: 13.60,
            weight: '',
            temp: 1,
            start_temp: '',
            stop_temp: '',
            degrees: '',
            cargos: [],
            cargos_fields: [],
            cargos_loading: false,
            references: [{id: '', reference_type_id: '', ref_number: ''}],
            packings: [{id: '', packing_type_id: '', pallets: '', exchange: 0}],
            sort: 0
        }],
        customer_price: 0,
        transporter_price: 0,
        firm_carrier_id: '',
        transporter_id: '',
        pn_truck: '',
        pn_trailer: '',
        status: '',
        status_name: '',
        status_class: '',
        damages: [],
        damage_desc: '',
        complete_damage: 1,
        response_message: '',
        additional_costs: [],
        files: [],
        activities: [],
        activity_message: '',
        assign_users: [],

        log_emails: []
    },
    infoData: {},
    ordersStats: {},
    filesCode: {linkCode: "", code: ""},
    groupage: {},
    createGroupage: '',
    createOrder: '',
    updateOrder: '',
    deleteOrder: '',
    copyOrder: '',
    stateOrder: '',
    closeOrder: '',
    sendEmail: '',
    filesNewCode: '',
    gpsLink: '',
    hideFile: '',
    deleteOrderAttach: '',
    dataFields: {
        contacts: [],
        operations: [],
        trailers: [],
        packings: [],
        references: [],
        loads: [],
        costs: [],
        users: [],
        customs: [],
        damages: []
    },
    dataSelectCustomers: {
        addresses: [],
        customers: [],
        users: [],
    },
    dataSelectTransporters: {
        transporters: []
    },
    loadingBtn: false,
    loadingBtnPdf: false,
    loadingBtnEmail: false,
    loadingBtnCancelled: false,
    loadingBtnDamage: false,
    loading: true,
    loadingModal: true,
    loadingBtnGroupage: false,
    error: ''
};


const order = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_ORDER:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_ORDER:
            return { ...state, loadingBtn: false, createOrder: action.payload, error: '' };
        case ERROR_CREATE_ORDER:
            return { ...state, loadingBtn: false, createOrder: '', error: action.payload.message };
        case ACTION_FETCH_ORDER:
            return { ...state, loading: true, error: '' };
        case FETCH_ORDER:
            return { ...state, loading: false, orderData: action.payload, error: '' };
        case ERROR_FETCH_ORDER:
            return { ...state, loading: false, orderData: '', error: action.payload.message };
        case FETCH_INFO:
            return { ...state, loadingModal: false, infoData: action.payload, error: '' };
        case ERROR_FETCH_INFO:
            return { ...state, loadingModal: false, infoData: '', error: action.payload.message };
        case CLEAR_ORDERS:
            return { ...state, ...INIT_STATE};
        case CLEAR_ORDER:
            return { ...state, orderData: INIT_STATE.orderData, createOrder: '', loadingModal: false, error: ''};
        case UPDATE_ORDER:
            let updateOrdersData = { ...state.orderData };
            updateOrdersData = action.payload.order;

            return { ...state, loadingBtn: false, updateOrder: action.payload.status, orderData: updateOrdersData, error: '' };
        case ERROR_UPDATE_ORDER:
            return { ...state, loadingBtn: false, updateOrder: '', error: action.payload.message };
        case CLEAR_ALERT_ORDER:
            return { ...state, updateOrder: '', createOrder: '', deleteOrder: '', stateOrder: '', closeOrder: '', copyOrder: '', sendEmail: '', filesNewCode: '', gpsLink: '', deleteOrderAttach: '', createGroupage: '', hideFile: '', groupage: {}, ordersStats: {}, error: '' };
        case ACTION_FETCH_ORDERS:
            return { ...state, loading: true, error: '' };
        case FETCH_ORDERS:
            return { ...state, loading: false, orders: action.payload, error: '' };
        case ERROR_FETCH_ORDERS:
            return { ...state, loading: false, orders: INIT_STATE.orders, error: action.payload.message };
        case DELETE_ORDER:
            return { ...state, loading: false, deleteOrder: action.payload, error: '' };
        case ERROR_DELETE_ORDER:
            return { ...state, loading: false, deleteOrder: '', error: action.payload.message };
        case COPY_ORDER:
            return { ...state, loadingBtn: false, copyOrder: action.payload, error: '' };
        case ERROR_COPY_ORDER:
            return { ...state, loadingBtn: false, copyOrder: '', error: action.payload.message };
        case STATE_ORDER:
            return { ...state, loading: false, stateOrder: action.payload, error: '' };
        case ERROR_STATE_ORDER:
            return { ...state, loading: false, stateOrder: '', error: action.payload.message };
        case CLOSE_ORDER:
            return { ...state, loading: false, closeOrder: action.payload, error: '' };
        case ERROR_CLOSE_ORDER:
            return { ...state, loading: false, closeOrder: '', error: action.payload.message };
        case DELETE_ORDER_ATTACH:
            return { ...state, loading: false, deleteOrderAttach: action.payload, error: '' };
        case ERROR_DELETE_ORDER_ATTACH:
            return { ...state, loading: false, deleteOrderAttach: '', error: action.payload.message };
        case ADD_LOG_ORDER:
            if(action.payload.type === 1){
                let ordersData = { ...state.orderData };
                ordersData.activities = action.payload.data;

                return { ...state, loading: false, orderData: ordersData, error: '' };
            }else{
                let ordersData = { ...state.infoData };
                ordersData.activities = action.payload.data;

                return { ...state, loading: false, infoData: ordersData, error: '' };
            }
        case ERROR_ADD_LOG_ORDER:
            return { ...state, loading: false, error: action.payload.message };

        case DELETE_LOG_ORDER:
            if(action.payload.type === 1){
                let ordersD = { ...state.orderData };
                ordersD.activities = action.payload.data;

                return { ...state, loading: false, orderData: ordersD, error: '' };
            }else{
                let ordersD = { ...state.infoData };
                ordersD.activities = action.payload.data;

                return { ...state, loading: false, infoData: ordersD, error: '' };
            }
            
        case ERROR_DELETE_LOG_ORDER:
            return { ...state, loading: false, error: action.payload.message };


        case EDIT_LOG_ORDER:
            if(action.payload.type === 1){
                let ord = { ...state.orderData };
                ord.activities = action.payload.data;

                return { ...state, loading: false, orderData: ord, error: '' };
            }else{
                let ord = { ...state.infoData };
                ord.activities = action.payload.data;

                return { ...state, loading: false, infoData: ord, error: '' };
            }
            
        case ERROR_EDIT_LOG_ORDER:
            return { ...state, loading: false, error: action.payload.message };


        //fetch data for fields
        case ACTION_DATA_FIELDS_ORDER:
            if(action.payload === 1){
                return { ...state, loadingModal: true, error: '' };
            }else{
                return { ...state, loading: true, error: '' };
            }
            
        case DATA_FIELDS_ORDER:
            return { ...state, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_ORDER:
            return { ...state, loadingModal: false, loading: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };

        //fetch data select for Customers
        case DATA_SELECT_CUSTOMERS_ORDER:
            return { ...state, dataSelectCustomers: action.payload, error: '' };
        case ERROR_DATA_SELECT_CUSTOMERS_ORDER:
            return { ...state, loading: false, dataSelectCustomers: INIT_STATE.dataSelectCustomers, error: action.payload.message };

        //fetch data select for Transporters
        case DATA_SELECT_TRANSPORTERS_ORDER:
            return { ...state, dataSelectTransporters: action.payload, error: '' };
        case ERROR_DATA_SELECT_TRANSPORTERS_ORDER:
            return { ...state, loading: false, dataSelectTransporters: INIT_STATE.dataSelectTransporters, error: action.payload.message };

        //fetch pdf
        case ACTION_PDF_ORDER:
            return { ...state, loadingBtnPdf: true, error: '' };
        case PDF_ORDER:
            return { ...state, loadingBtnPdf: false, error: '' };
        case ERROR_PDF_ORDER:
            return { ...state, loadingBtnPdf: false, error: action.payload.message };

        //Send Email
        case ACTION_EMAIL_ORDER:
            return { ...state, loadingBtnEmail: true, error: '' };
        case EMAIL_ORDER:
            let email_order = { ...state.orderData };
            email_order = action.payload.data;

            return { ...state, loadingBtnEmail: false, sendEmail: action.payload.status, orderData: email_order, error: '' };
        case ERROR_EMAIL_ORDER:
            return { ...state, loadingBtnEmail: false, sendEmail: '', error: action.payload.message };
        
        //Send Email Plate
        case EMAIL_PLATE_ORDER:
            let plate_order = {...state.orderData};
            plate_order.activities = action.payload.activities;
            plate_order.log_emails = action.payload.email_log;
            
            return { ...state, loadingBtnEmail: false, sendEmail: action.payload.status, orderData: plate_order,  error: '' };
        case ERROR_EMAIL_PLATE_ORDER:
            return { ...state, loadingBtnEmail: false, sendEmail: '', error: action.payload.message };

        //Cancelled order
        case ACTION_CANCELLED_ORDER:
            return { ...state, loadingBtnCancelled: true, error: '' };
        case CANCELLED_ORDER:
            let cancelled_order = {...state.orderData};

            if(Number(action.payload) === 1){
                cancelled_order.status = 6;
                cancelled_order.status_name = "Cancelled";
                cancelled_order.status_class = "dark";

                cancelled_order.customer_price = 0;
                cancelled_order.transporter_price = 0;
                cancelled_order.additional_costs = [];
            }else{
                cancelled_order.status = 1;
                cancelled_order.status_name = "Created";
                cancelled_order.status_class = "danger";

                cancelled_order.firm_carrier_id = "";
                cancelled_order.transporter_id = "";
                cancelled_order.pn_truck = "";
                cancelled_order.pn_trailer = "";
            }

            return { ...state, loadingBtnCancelled: false, orderData: cancelled_order, error: '' };
        case ERROR_CANCELLED_ORDER:
            return { ...state, loadingBtnCancelled: false, error: action.payload.message };
        
        //Damage order
        case ACTION_DAMAGE_ORDER:
            return { ...state, loadingBtnDamage: true, error: '' };
        case DAMAGE_ORDER:
            let damage_order = {...state.orderData};
            damage_order.status = 5;
            damage_order.status_name = "Damage";
            damage_order.status_class = "purple";
            
            return { ...state, loadingBtnDamage: false, orderData: damage_order, error: '' };
        case ERROR_DAMAGE_ORDER:
            return { ...state, loadingBtnDamage: false, error: action.payload.message };

        case FETCH_ORDERS_STATS:
            return { ...state, loadingModal: false, ordersStats: action.payload, error: '' };
        case ERROR_FETCH_ORDERS_STATS:
            return { ...state, loadingModal: false, ordersStats: {}, error: action.payload.message };

        case FETCH_FILES_CODE:
            return { ...state, loadingModal: false, filesCode: action.payload, error: '' };
        case ERROR_FETCH_FILES_CODE:
            return { ...state, loadingModal: false, filesCode: {}, error: action.payload.message };

        case FILES_NEW_CODE:
            let new_code = {...state.filesCode};
            new_code.code = action.payload.code;
            new_code.linkCode = action.payload.linkCode;

            return { ...state, filesNewCode: action.payload.status, filesCode: new_code, error: '' };
        case ERROR_FILES_NEW_CODE:
            return { ...state, filesNewCode: "", error: action.payload.message };

        case GPS_LINK:
            return { ...state, gpsLink: action.payload, error: '' };
        case ERROR_GPS_LINK:
            return { ...state, gpsLink: "", error: action.payload.message };

        case HIDE_FILE_ORDER:
            return { ...state, hideFile: action.payload, error: '' };
        case ERROR_HIDE_FILE_ORDER:
            return { ...state, hideFile: "", error: action.payload.message };

        case FETCH_GROUPAGE:
            return { ...state, loadingBtn: false, groupage: action.payload, error: '' };
        case ERROR_FETCH_GROUPAGE:
            return { ...state, loadingBtn: false, groupage: {}, error: action.payload.message };

        case ACTION_CREATE_GROUPAGE:
            return { ...state, loadingBtnGroupage: true, error: '' };
        case CREATE_GROUPAGE:
            return { ...state, loadingBtnGroupage: false, createGroupage: action.payload, error: '' };
        case ERROR_CREATE_GROUPAGE:
            return { ...state, loadingBtnGroupage: false, createGroupage: '', error: action.payload.message };

        //seting list heading
        case SET_CURRENT_PAGE_ORDER:
            return { ...state, currentPage: action.payload };
        case SET_SORT_ORDER:
            return { ...state, sortObj: action.payload };
        case SET_SELECTED_PAGE_SIZE_ORDER:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_SEARCH_ORDER:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE_ORDER:
            return { ...state, searchValue: action.payload };
        case SET_FILTER_ORDER:
            return { ...state, searchFilter: action.payload };
        case CLEAR_FILTER_ORDER:
            return { ...state, searchFilter: INIT_STATE.searchFilter };
        default: return state;
    }
}

export default order;
