import {
    ACTION_OFFER,
    CREATE_OFFER,
    ERROR_CREATE_OFFER,
    ACTION_FETCH_OFFER,
    FETCH_OFFER,
    ERROR_FETCH_OFFER,
    CLEAR_OFFER,
    UPDATE_OFFER,
    ERROR_UPDATE_OFFER,
    CLEAR_ALERT_OFFER,
    FETCH_OFFERS,
    ERROR_FETCH_OFFERS,
    ACTION_FETCH_OFFERS,
    ACTION_EMAIL_OFFER,
    EMAIL_OFFER,
    ERROR_EMAIL_OFFER,
    DELETE_OFFER,
    ERROR_DELETE_OFFER,
    ACTION_FETCH_VALUES,
    FETCH_VALUES,
    ERROR_FETCH_VALUES,
    ACTION_DATA_FIELDS_OFFER,
    DATA_FIELDS_OFFER,
    ERROR_DATA_FIELDS_OFFER,
    ACTION_PDF_OFFER,
    PDF_OFFER,
    ERROR_PDF_OFFER,
    ACTION_COPY_OFFER,
    COPY_OFFER,
    ERROR_COPY_OFFER
} from './types';

const INIT_STATE = {
    offers: {
        data: [],
        totalItemCount: 0
    },
    offerData: {
        firm_id: '',
        business_id: '',
        title: '',
        manuscripts: '',
        content: '',
        message: '',
        contacts: [],
        users: [],
        options: [],
        send_at: '',
        created_user: ''
    },
    dataFields: {
        cargos: [],
        users: [],
        contacts: [],
        businesses: [],
        terms: []
    },
    valuesOffer: [],
    createOffer: '',
    updateOffer: '',
    emailOffer: '',
    deleteOffer: '',
    copyOffer: '',
    loadingCopyOffer: false,
    loadingBtn: false,
    loadingBtnPdf: false,
    loadingBtnEmail: false,
    loadingModal: false,
    loadingTab: false,
    loadingValues: false,
    loading: true,
    error: ''
};


const offer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_OFFER:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_OFFER:
            return { ...state, loadingBtn: false, createOffer: action.payload, error: '' };
        case ERROR_CREATE_OFFER:
            return { ...state, loadingBtn: false, createOffer: '', error: action.payload.message };
        case ACTION_FETCH_OFFER:
            return { ...state, loadingTab: true, error: '' };
        case FETCH_OFFER:
            return { ...state, loadingTab: false, offerData: action.payload, error: '' };
        case ERROR_FETCH_OFFER:
            return { ...state, loadingTab: false, offerData: '', error: action.payload.message };
        case CLEAR_OFFER:
            return { ...state, loadingBtn: false, loadingBtnPdf: false, loadingBtnEmail: false, loading: false, loadingTab: false, offerData: INIT_STATE.offerData, error: '' };
        case UPDATE_OFFER:
            return { ...state, loadingBtn: false, updateOffer: action.payload, error: '' };
        case ERROR_UPDATE_OFFER:
            return { ...state, loadingBtn: false, updateOffer: '', error: action.payload.message };
        case CLEAR_ALERT_OFFER:
            return { ...state, updateOffer: '', createOffer: '', emailOffer: '', deleteOffer: '', activeOffer: '', sendEmail: '', valuesOffer: [], copyOffer: '', error: '' };
        case ACTION_FETCH_OFFERS:
            return { ...state, loading: true, error: '' };
        case FETCH_OFFERS:
            return { ...state, loading: false, offers: action.payload, error: '' };
        case ERROR_FETCH_OFFERS:
            return { ...state, loading: false, offers: INIT_STATE.offers, error: action.payload.message };
        case ACTION_EMAIL_OFFER:
            return { ...state, loadingBtnEmail: true, error: '' };
        case EMAIL_OFFER:
            return { ...state, loadingBtnEmail: false, emailOffer: action.payload, error: '' };
        case ERROR_EMAIL_OFFER:
            return { ...state, loadingBtnEmail: false, emailOffer: '', error: action.payload.message };
        case DELETE_OFFER:
            return { ...state, loadingTab: false, deleteOffer: action.payload, error: '' };
        case ERROR_DELETE_OFFER:
            return { ...state, loadingTab: false, deleteOffer: '', error: action.payload.message };
        case ACTION_FETCH_VALUES:
            return { ...state, loadingValues: true, error: '' };
        case FETCH_VALUES:
            return { ...state, loadingValues: false, valuesOffer: action.payload, error: '' };
        case ERROR_FETCH_VALUES:
            return { ...state, loadingValues: false, valuesOffer: [], error: action.payload.message };

        case ACTION_COPY_OFFER:
            return { ...state, loadingCopyOffer: action.payload, error: '' };   
        case COPY_OFFER:
            return { ...state, loadingCopyOffer: false, copyOffer: action.payload, error: '' };
        case ERROR_COPY_OFFER:
            return { ...state, loadingCopyOffer: false, copyOffer: '', error: action.payload.message };

        //fetch data for fields
        case ACTION_DATA_FIELDS_OFFER:
            return { ...state, loading: true, error: '' };
        case DATA_FIELDS_OFFER:
            return { ...state, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_OFFER:
            return { ...state, loading: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };

        //fetch pdf
        case ACTION_PDF_OFFER:
            return { ...state, loadingBtnPdf: true, error: '' };
        case PDF_OFFER:
            return { ...state, loadingBtnPdf: false, error: '' };
        case ERROR_PDF_OFFER:
            return { ...state, loadingBtnPdf: false, error: action.payload.message };
        
        default: return state;
    }
}

export default offer;
