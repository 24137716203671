import React from "react"
import { Container, Row, Col } from "reactstrap"
import packageJson from '../../../package.json';

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col sm={6}>{new Date().getFullYear()} © MyDS. Ver.: {packageJson.version}</Col>
            <Col sm={6}>
              <div className="text-sm-end d-none d-sm-block">
                Design & Development <a href="https://humantwo.gr" rel="noreferrer" className="text-primary" target="_blank">HumanTwo</a>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
