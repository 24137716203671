/**************** GROUPS *********************/
export const ACTION_GROUP = "ACTION_GROUP";
export const CREATE_GROUP = "CREATE_GROUP";
export const ERROR_CREATE_GROUP = "ERROR_CREATE_GROUP";

export const ACTION_FETCH_GROUP = "ACTION_FETCH_GROUP";
export const FETCH_GROUP = "FETCH_GROUP";
export const ERROR_FETCH_GROUP = "ERROR_FETCH_GROUP";
export const CLEAR_GROUP = "CLEAR_GROUP";

export const UPDATE_GROUP = "UPDATE_GROUP";
export const ERROR_UPDATE_GROUP = "ERROR_UPDATE_GROUP";

export const CLEAR_ALERT_GROUP = "CLEAR_ALERT_GROUP";

export const FETCH_GROUPS = "FETCH_GROUPS";
export const ACTION_FETCH_GROUPS = "ACTION_FETCH_GROUPS";
export const ERROR_FETCH_GROUPS = "ERROR_FETCH_GROUPS";

export const DELETE_GROUP = "DELETE_GROUP";
export const ERROR_DELETE_GROUP = "ERROR_DELETE_GROUP";

export const EMAIL_GROUP = "EMAIL_GROUP";
export const ERROR_EMAIL_GROUP = "ERROR_EMAIL_GROUP";

export const CREATE_CONTACTS_GROUP = "CREATE_CONTACTS_GROUP";
export const ERROR_CREATE_CONTACTS_GROUP = "ERROR_CREATE_CONTACTS_GROUP";

export const DELETE_CONTACT_GROUP = "DELETE_CONTACT_GROUP";
export const ERROR_DELETE_CONTACT_GROUP = "ERROR_DELETE_CONTACT_GROUP";

export const ACTION_DATA_FIELDS_GROUP = "ACTION_DATA_FIELDS_GROUP";
export const DATA_FIELDS_GROUP = "DATA_FIELDS_GROUP";
export const ERROR_DATA_FIELDS_GROUP = "ERROR_DATA_FIELDS_GROUP";

export const SET_SEARCH_GROUP = "SET_SEARCH_GROUP";
export const SET_SEARCH_VALUE_GROUP = "SET_SEARCH_VALUE_GROUP";




