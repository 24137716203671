/**************** CONTACTS *********************/
export const ACTION_CONTACT = "ACTION_CONTACT";
export const CREATE_CONTACT = "CREATE_CONTACT";
export const ERROR_CREATE_CONTACT = "ERROR_CREATE_CONTACT";

export const ACTION_FETCH_CONTACT = "ACTION_FETCH_CONTACT";
export const FETCH_CONTACT = "FETCH_CONTACT";
export const ERROR_FETCH_CONTACT = "ERROR_FETCH_CONTACT";
export const CLEAR_CONTACT = "CLEAR_CONTACT";

export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const ERROR_UPDATE_CONTACT = "ERROR_UPDATE_CONTACT";

export const CLEAR_ALERT_CONTACT = "CLEAR_ALERT_CONTACT";

export const FETCH_CONTACTS = "FETCH_CONTACTS";
export const ACTION_FETCH_CONTACTS = "ACTION_FETCH_CONTACTS";
export const ERROR_FETCH_CONTACTS = "ERROR_FETCH_CONTACTS";

export const DELETE_CONTACT = "DELETE_CONTACT";
export const ERROR_DELETE_CONTACT = "ERROR_DELETE_CONTACT";

export const ACTIVE_CONTACT = "ACTIVE_CONTACT";
export const ERROR_ACTIVE_CONTACT = "ERROR_ACTIVE_CONTACT";

export const EMAIL_CONTACT = "EMAIL_CONTACT";
export const ERROR_EMAIL_CONTACT = "ERROR_EMAIL_CONTACT";

export const ACTION_DATA_FIELDS_CONTACT = "ACTION_DATA_FIELDS_CONTACT";
export const DATA_FIELDS_CONTACT = "DATA_FIELDS_CONTACT";
export const ERROR_DATA_FIELDS_CONTACT = "ERROR_DATA_FIELDS_CONTACT";





