import {
    ACTION_PLACE,
    CREATE_PLACE,
    ERROR_CREATE_PLACE,
    ACTION_FETCH_PLACE,
    FETCH_PLACE,
    ERROR_FETCH_PLACE,
    CLEAR_PLACE,
    UPDATE_PLACE,
    ERROR_UPDATE_PLACE,
    CLEAR_ALERT_PLACE,
    FETCH_PLACES,
    ERROR_FETCH_PLACES,
    ACTION_FETCH_PLACES,
    DELETE_PLACE,
    ERROR_DELETE_PLACE,
    ACTIVE_PLACE,
    ERROR_ACTIVE_PLACE,
    ACTION_DATA_FIELDS_PLACE,
    DATA_FIELDS_PLACE,
    ERROR_DATA_FIELDS_PLACE,
    EMAIL_PLACE,
    ERROR_EMAIL_PLACE,
    SET_CURRENT_PAGE_PLACE,
    SET_SORT_PLACE,
    SET_SELECTED_PAGE_SIZE_PLACE,
    SET_SEARCH_PLACE,
    SET_SEARCH_VALUE_PLACE,
} from './types';

const INIT_STATE = {
    places: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 10,
    search: "",
    searchValue: "",
    currentPage: 1,
    sortObj: null,
    placeData: {
        name: '',
        address: '',
        postal: '',
        country_id: '',
        lat: '',
        lng: '',
        person: '',
        email: '',
        customs: 2,
        message: '',
        phone: '',
        phone_code: '',
        phone_iso: '',
        mobile: '',
        mobile_code: '',
        mobile_iso: '',
        zoom: 4,
        created_user: '',
        created_at: '',
        book_emails: [],
        book_desc: ''
    },
    createPlace: '',
    updatePlace: '',
    deletePlace: '',
    activePlace: '',
    sendEmail: '',
    dataFields: {
        countries: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
};


const place = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_PLACE:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_PLACE:
            return { ...state, loadingBtn: false, createPlace: action.payload, error: '' };
        case ERROR_CREATE_PLACE:
            return { ...state, loadingBtn: false, createPlace: '', error: action.payload.message };
        case ACTION_FETCH_PLACE:
            return { ...state, loading: true, error: '' };
        case FETCH_PLACE:
            return { ...state, loading: false, placeData: action.payload, error: '' };
        case ERROR_FETCH_PLACE:
            return { ...state, loading: false, placeData: '', error: action.payload.message };
        case CLEAR_PLACE:
            return { ...state, loadingBtn: false, loading: false, placeData: INIT_STATE.placeData, error: '' };
        case UPDATE_PLACE:
            return { ...state, loadingBtn: false, updatePlace: action.payload, error: '' };
        case ERROR_UPDATE_PLACE:
            return { ...state, loadingBtn: false, updatePlace: '', error: action.payload.message };
        case CLEAR_ALERT_PLACE:
            return { ...state, updatePlace: '', createPlace: '', deletePlace: '', activePlace: '', sendEmail: '', changePasswordPlace: '', error: '' };
        case ACTION_FETCH_PLACES:
            return { ...state, loading: true, error: '' };
        case FETCH_PLACES:
            return { ...state, loading: false, places: action.payload, error: '' };
        case ERROR_FETCH_PLACES:
            return { ...state, loading: false, places: INIT_STATE.places, error: action.payload.message };
        case DELETE_PLACE:
            return { ...state, loading: false, deletePlace: action.payload, error: '' };
        case ERROR_DELETE_PLACE:
            return { ...state, loading: false, deletePlace: '', error: action.payload.message };
        case ACTIVE_PLACE:
            return { ...state, loading: false, activePlace: action.payload, error: '' };
        case ERROR_ACTIVE_PLACE:
            return { ...state, loading: false, activePlace: '', error: action.payload.message };
        case EMAIL_PLACE:
            return { ...state, loading: false, sendEmail: action.payload, error: '' };
        case ERROR_EMAIL_PLACE:
            return { ...state, loading: false, sendEmail: '', error: action.payload.message };


        //fetch data for fields
        case ACTION_DATA_FIELDS_PLACE:
            return { ...state, loading: true, error: '' };
        case DATA_FIELDS_PLACE:
            return { ...state, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_PLACE:
            return { ...state, loading: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };

        //seting list heading
        case SET_CURRENT_PAGE_PLACE:
            return { ...state, currentPage: action.payload };
        case SET_SORT_PLACE:
            return { ...state, sortObj: action.payload };
        case SET_SELECTED_PAGE_SIZE_PLACE:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_SEARCH_PLACE:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE_PLACE:
            return { ...state, searchValue: action.payload };
        default: return state;
    }
}

export default place;
