import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';  
import { ClearCacheProvider } from 'react-clear-cache';


const app = (
  <ClearCacheProvider isLatestVersion={true}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ClearCacheProvider>
)

ReactDOM.render(app, document.getElementById("root"))

serviceWorker.unregister()
