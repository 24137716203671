import {
    ACTION_FETCH_CHAT,
    FETCH_CHAT,
    ERROR_FETCH_CHAT,
    ADD_CHAT,
    ERROR_ADD_CHAT,
    LOCATION_CHAT,
    ERROR_LOCATION_CHAT,
    FILE_CHAT,
    ERROR_FILE_CHAT,
    READ_CHAT,
    ERROR_READ_CHAT,
    CHARGE_CHAT,
    ERROR_CHARGE_CHAT,
    DELETE_CHAT,
    ERROR_DELETE_CHAT,
    SET_CHAT,
    CLEAR_CHAT
} from './types';

const INIT_STATE = {
    chats: {
        data: [],
        title: '',
        users: [],
        customers: []
    },
    addChat: '',
    locationChat: '',
    fileChat: '',
    seenChat: '',
    readChat: '',
    chargeChat: '',
    deleteChat: '',
    loading: false,
    error: ''
};


const notification = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_FETCH_CHAT:
            return { ...state, loading: true, error: '' };
        case FETCH_CHAT:
            return { ...state, loading: false, chats: action.payload, error: '' };
        case ERROR_FETCH_CHAT:
            return { ...state, loading: false, error: action.payload.message };
        case ADD_CHAT:
            let new_chat_text = {...state.chats};

            new_chat_text.data.push(action.payload.data);

            return { ...state, loading: false, chats: new_chat_text, addChat: action.payload.status, error: '' };
        case ERROR_ADD_CHAT:
            return { ...state, loading: false, addChat: '', error: action.payload.message };
        case LOCATION_CHAT:
            let new_chat_location = {...state.chats};

            new_chat_location.data.push(action.payload.data);

            return { ...state, loading: false, chats: new_chat_location, locationChat: action.payload.status, error: '' };
        case ERROR_LOCATION_CHAT:
            return { ...state, loading: false, locationChat: '', error: action.payload.message };
        case FILE_CHAT:
            let new_chat_file = {...state.chats};

            new_chat_file.data.push(action.payload.data);

            return { ...state, loading: false, chats: new_chat_file, fileChat: action.payload.status, error: '' };
        case ERROR_FILE_CHAT:
            return { ...state, loading: false, fileChat: '', error: action.payload.message };
        case READ_CHAT:
            return { ...state, loading: false, readChat: action.payload, error: '' };
        case ERROR_READ_CHAT:
            return { ...state, loading: false, readChat: '', error: action.payload.message };
        case CHARGE_CHAT:
            return { ...state, loading: false, chargeChat: action.payload, error: '' };
        case ERROR_CHARGE_CHAT:
            return { ...state, loading: false, chargeChat: '', error: action.payload.message };
        case DELETE_CHAT:
            return { ...state, loading: false, deleteChat: action.payload, error: '' };
        case ERROR_DELETE_CHAT:
            return { ...state, loading: false, deleteChat: '', error: action.payload.message };

        //seting list heading
        case SET_CHAT:
            return { ...state, chats: action.payload };
        case CLEAR_CHAT:
            return { ...state, chats: INIT_STATE.chats, seenChat: '', readChat: '', chargeChat: '', addChat: '', locationChat: '', fileChat: '', deleteChat: '', loading: false, error: '' };

        default: return state;
    }
}

export default notification
