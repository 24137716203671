/**************** STATS *********************/

export const ACTION_SEARCH_STATS = "ACTION_SEARCH_STATS";
export const SEARCH_STATS = "SEARCH_STATS";
export const ERROR_SEARCH_STATS = "ERROR_SEARCH_STATS";

export const ACTION_SEARCH_PROFIT_RATES = "ACTION_SEARCH_PROFIT_RATES";
export const SEARCH_PROFIT_RATES = "SEARCH_PROFIT_RATES";
export const ERROR_SEARCH_PROFIT_RATES = "ERROR_SEARCH_PROFIT_RATES";

export const ACTION_FIRMS_STATS = "ACTION_FIRMS_STATS";
export const FIRMS_STATS = "FIRMS_STATS";
export const ERROR_FIRMS_STATS = "ERROR_FIRMS_STATS";

export const ACTION_DATA_FIELDS_STATS = "ACTION_DATA_FIELDS_STATS";
export const DATA_FIELDS_STATS = "DATA_FIELDS_STATS";
export const ERROR_DATA_FIELDS_STATS = "ERROR_DATA_FIELDS_STATS";

export const CLEAR_STATS = "CLEAR_STATS";
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const CLEAR_SEARCH_PROFIT_RATES = "CLEAR_SEARCH_PROFIT_RATES";
export const CLEAR_FIRMS_STATS = "CLEAR_FIRMS_STATS";
export const CLEAR_ALERT_STATS = "CLEAR_ALERT_STATS";







