import {
    ACTION_BOOKING,
    CREATE_BOOKING,
    ERROR_CREATE_BOOKING,
    CLEAR_BOOKING,
    CLEAR_BOOKINGS,
    CLEAR_ALERT_BOOKING,
    FETCH_BOOKINGS,
    ERROR_FETCH_BOOKINGS,
    ACTION_FETCH_BOOKINGS,
    STATUS_BOOKING,
    ERROR_STATUS_BOOKING,
    DELETE_BOOKING,
    ERROR_DELETE_BOOKING,
    SET_CURRENT_PAGE_BOOKING,
    SET_SORT_BOOKING,
    SET_SELECTED_PAGE_SIZE_BOOKING,
    SET_SEARCH_BOOKING,
    SET_SEARCH_VALUE_BOOKING,
    SET_FILTER_BOOKING,
    CLEAR_FILTER_BOOKING
} from './types';

const INIT_STATE = {
    bookings: {
        data: [],
        totalItemCount: 0,
        dataFields: []
    },
    selectedPageSize: 10,
    search: "",
    searchValue: "",
    bookingData: {
        number_id: '',
        business_id: 1,
        import_export: 1,
        loading: '',
        order_details: [{
            l_date: null,
            l_time: null,
            l_place_id: '',
            l_notes: '',
            l_places: [],
            l_loading: false,
            u_date: null,
            u_time: null,
            u_place_id: '',
            u_notes: '',
            u_places: [],
            u_loading: false,
            trailer_type_id: '',
            load_type_id: 1,
            load_meters: 13.60,
            weight: '',
            temp: 1,
            start_temp: '',
            stop_temp: '',
            degrees: '',
            cargos: [],
            cargos_fields: [],
            cargos_loading: false,
            references: [{id: '', reference_type_id: '', ref_number: ''}],
            packings: [{id: '', packing_type_id: '', pallets: '', exchange: 0}],
            sort: 0
        }],
        customer_price: 0,
        additional_costs: []
    },
    searchFilter: {
        business_id: '',
        order_id: '',
        customer: '',
        load_option: 1,
        loading: '',
        del_option: 1,
        delivery: '',
        loading_date_from: null,
        loading_date_to: null,
        trailers: [],
        cargos: [],
        load_type: '',
        status: '',
        operations: [],
        ref_number: ''
    },
    currentPage: 1,
    sortObj: null,
    createBooking: '',
    deleteBooking: '',
    statusBooking: '',
    loadingBtn: false,
    loading: true,
    loadingModal: true,
    error: ''
};


const booking = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_BOOKING:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_BOOKING:
            return { ...state, loadingBtn: false, createBooking: action.payload, error: '' };
        case ERROR_CREATE_BOOKING:
            return { ...state, loadingBtn: false, createBooking: '', error: action.payload.message };
        case CLEAR_BOOKING:
            return { ...state, bookingData: INIT_STATE.bookingData, createBooking: '', loadingModal: false, error: ''};
        case CLEAR_BOOKINGS:
            return { ...state, ...INIT_STATE};
        case CLEAR_ALERT_BOOKING:
            return { ...state, createBooking: '', deleteBooking: '', statusBooking: '',  error: '' };
        case ACTION_FETCH_BOOKINGS:
            return { ...state, loading: true, error: '' };
        case FETCH_BOOKINGS:
            return { ...state, loading: false, bookings: action.payload, error: '' };
        case ERROR_FETCH_BOOKINGS:
            return { ...state, loading: false, bookings: INIT_STATE.bookings, error: action.payload.message };
        case STATUS_BOOKING:
            return { ...state, loading: false, statusBooking: action.payload, error: '' };
        case ERROR_STATUS_BOOKING:
            return { ...state, loading: false, statusBooking: '', error: action.payload.message };
        case DELETE_BOOKING:
            return { ...state, loading: false, deleteBooking: action.payload, error: '' };
        case ERROR_DELETE_BOOKING:
            return { ...state, loading: false, deleteBooking: '', error: action.payload.message };


        //seting list heading
        case SET_CURRENT_PAGE_BOOKING:
            return { ...state, currentPage: action.payload };
        case SET_SORT_BOOKING:
            return { ...state, sortObj: action.payload };
        case SET_SELECTED_PAGE_SIZE_BOOKING:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_SEARCH_BOOKING:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE_BOOKING:
            return { ...state, searchValue: action.payload };
        case SET_FILTER_BOOKING:
            return { ...state, searchFilter: action.payload };
        case CLEAR_FILTER_BOOKING:
            return { ...state, searchFilter: INIT_STATE.searchFilter };
        default: return state;
    }
}

export default booking;
