/**************** CALENDAR *********************/
export const ACTION_CALENDAR = "ACTION_CALENDAR";

export const CLEAR_ALERT_CALENDAR = "CLEAR_ALERT_CALENDAR";

export const FETCH_ORDERS_CALENDAR = "FETCH_ORDERS_CALENDAR";
export const ACTION_FETCH_ORDERS_CALENDAR = "ACTION_FETCH_ORDERS_CALENDAR";
export const ERROR_FETCH_ORDERS_CALENDAR = "ERROR_FETCH_ORDERS_CALENDAR";

export const SET_FILTER_CALENDAR = "SET_FILTER_CALENDAR";
export const CLEAR_FILTER_CALENDAR = "CLEAR_FILTER_CALENDAR";

export const ACTION_SEARCH_FIELDS_CALENDAR = "ACTION_SEARCH_FIELDS_CALENDAR";
export const SEARCH_FIELDS_CALENDAR = "SEARCH_FIELDS_CALENDAR";
export const ERROR_SEARCH_FIELDS_CALENDAR = "ERROR_SEARCH_FIELDS_CALENDAR";




