import axios from 'axios';

import { NOTIFICATION, HEADERS } from '../../constants/defaultValues';
import { setErrorAction } from '../../helpers/Utils';
import {
  ACTION_FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS,
  ERROR_FETCH_NOTIFICATIONS,
  SEEN_NOTIFICATIONS,
  ERROR_SEEN_NOTIFICATIONS,
  READ_NOTIFICATION,
  ERROR_READ_NOTIFICATION,
  ALL_READ_NOTIFICATIONS,
  ERROR_ALL_READ_NOTIFICATIONS,
  SET_CURRENT_PAGE_NOTIFICATION,
  SET_SELECTED_PAGE_SIZE_NOTIFICATION,
  SET_NOTIFICATION,
  CLEAR_NOTIFICATIONS
} from './types';

/****************************    START ACTION NOTIFICATIONS   ******************************************/

export const actionFetchNotifications = () => ({
  type: ACTION_FETCH_NOTIFICATIONS
});

/****************************    END ACTION NOTIFICATIONS   ******************************************/

/****************************    START FETCH NOTIFICATIONS   ******************************************/

export const fetchNotificationsSuccess = (Notifications) => ({
  type: FETCH_NOTIFICATIONS,
  payload: Notifications
});
export const fetchNotificationsError = (message) => ({
  type: ERROR_FETCH_NOTIFICATIONS,
  payload: { message }
});

export const startFetchNotifications = (locale, token, filter) => {
  const headers = HEADERS;
  return dispatch => {
    dispatch(actionFetchNotifications())
    headers['X-localization'] = locale;
    headers['Authorization'] = `Bearer ${token}`;

    axios.get(`${NOTIFICATION}?pageSize=${filter.pageSize}&currentPage=${filter.currentPage}`, { headers })
      .then(res => {
        dispatch(fetchNotificationsSuccess(res.data))
      }).catch(error => {
        try {
          dispatch(fetchNotificationsError(setErrorAction(error.response.data.errors)))
        } catch (error) {
          dispatch(fetchNotificationsError('Something went wrong. Please try again later.'))
        }
      });
  }
};

/****************************    END FETCH NOTIFICATIONS   ******************************************/

/****************************    START SEEN NOTIFICATIONS   ******************************************/
export const seenNotificationsSuccess = (seenNotifications) => ({
  type: SEEN_NOTIFICATIONS,
  payload: seenNotifications
});
export const seenNotificationsError = (message) => ({
  type: ERROR_SEEN_NOTIFICATIONS,
  payload: { message }
});

export const startSeenNotifications = (locale, token) => {
  const headers = HEADERS;
  return dispatch => {
    headers['X-localization'] = locale;
    headers['Authorization'] = `Bearer ${token}`;

    axios.get(`${NOTIFICATION}/seen`, { headers })
      .then(res => {
        dispatch(seenNotificationsSuccess(res.data))
      }).catch(error => {
        try {
          dispatch(seenNotificationsError(setErrorAction(error.response.data.errors)))
        } catch (error) {
          dispatch(seenNotificationsError('Something went wrong. Please try again later.'))
        }
      });
  }
};
/****************************    END SEEN NOTIFICATIONS   ******************************************/

/****************************    START READ NOTIFICATION   ******************************************/
export const readNotificationSuccess = (readNotification) => ({
  type: READ_NOTIFICATION,
  payload: readNotification
});
export const readNotificationError = (message) => ({
  type: ERROR_READ_NOTIFICATION,
  payload: { message }
});

export const startReadNotification = (locale, token, id, index) => {
  const headers = HEADERS;
  return dispatch => {
    headers['X-localization'] = locale;
    headers['Authorization'] = `Bearer ${token}`;

    axios.put(`${NOTIFICATION}/read`, { notification_id: id }, { headers })
      .then(res => {
        dispatch(readNotificationSuccess({status: res.data, index: index}))
      }).catch(error => {
        try {
          dispatch(readNotificationError(setErrorAction(error.response.data.errors)))
        } catch (error) {
          dispatch(readNotificationError('Something went wrong. Please try again later.'))
        }
      });
  }
};
/****************************    END READ NOTIFICATION   ******************************************/

/****************************    START ALL READ NOTIFICATIONS   ******************************************/
export const allReadNotificationsSuccess = (allReadNotifications) => ({
  type: ALL_READ_NOTIFICATIONS,
  payload: allReadNotifications
});
export const allReadNotificationsError = (message) => ({
  type: ERROR_ALL_READ_NOTIFICATIONS,
  payload: { message }
});

export const startAllReadNotifications = (locale, token) => {
  const headers = HEADERS;
  return dispatch => {
    headers['X-localization'] = locale;
    headers['Authorization'] = `Bearer ${token}`;

    axios.get(`${NOTIFICATION}/allRead`, { headers })
      .then(res => {
        dispatch(allReadNotificationsSuccess(res.data))
      }).catch(error => {
        try {
          dispatch(allReadNotificationsError(setErrorAction(error.response.data.errors)))
        } catch (error) {
          dispatch(allReadNotificationsError('Something went wrong. Please try again later.'))
        }
      });
  }
};
/****************************    END ALL READ NOTIFICATIONS   ******************************************/

/****************************    START LIST HEADING SETTINGS   ******************************************/

export const setCurrentPage = (currentPage) => {
  return async dispatch => {
    await dispatch({ type: SET_CURRENT_PAGE_NOTIFICATION, payload: currentPage })

    return true;
  }
};

export const setSelectedPageSize = (size) => ({
  type: SET_SELECTED_PAGE_SIZE_NOTIFICATION,
  payload: size
});

export const setNotification = (notification) => ({
  type: SET_NOTIFICATION,
  payload: notification
});

export const clearNotifications = () => ({
  type: CLEAR_NOTIFICATIONS
});

/****************************    END LIST HEADING SETTINGS   ******************************************/











