/**************** ACCOUNT *********************/
export const ACTION_ACCOUNT = "ACTION_ACCOUNT";
export const CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const ERROR_CREATE_ACCOUNT = "ERROR_CREATE_ACCOUNT";

export const ACTION_FETCH_ACCOUNT = "ACTION_FETCH_ACCOUNT";
export const FETCH_ACCOUNT = "FETCH_ACCOUNT";
export const ERROR_FETCH_ACCOUNT = "ERROR_FETCH_ACCOUNT";
export const CLEAR_ACCOUNT = "CLEAR_ACCOUNT";

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT";
export const ERROR_UPDATE_ACCOUNT = "ERROR_UPDATE_ACCOUNT";

export const CLEAR_ALERT_ACCOUNT = "CLEAR_ALERT_ACCOUNT";

export const FETCH_ACCOUNTS = "FETCH_ACCOUNTS";
export const ACTION_FETCH_ACCOUNTS = "ACTION_FETCH_ACCOUNTS";
export const ERROR_FETCH_ACCOUNTS = "ERROR_FETCH_ACCOUNTS";

export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const ERROR_DELETE_ACCOUNT = "ERROR_DELETE_ACCOUNT";

export const ACTIVE_ACCOUNT = "ACTIVE_ACCOUNT";
export const ERROR_ACTIVE_ACCOUNT = "ERROR_ACTIVE_ACCOUNT";

export const WELCOME_ACCOUNT = "WELCOME_ACCOUNT";
export const ERROR_WELCOME_ACCOUNT = "ERROR_WELCOME_ACCOUNT";

export const CHANGE_PASSWORD_ACCOUNT = "CHANGE_PASSWORD_ACCOUNT";
export const ERROR_CHANGE_PASSWORD_ACCOUNT = "ERROR_CHANGE_PASSWORD_ACCOUNT";

export const ACTION_DATA_FIELDS_ACCOUNT = "ACTION_DATA_FIELDS_ACCOUNT";
export const DATA_FIELDS_ACCOUNT = "DATA_FIELDS_ACCOUNT";
export const ERROR_DATA_FIELDS_ACCOUNT = "ERROR_DATA_FIELDS_ACCOUNT";

export const DATA_SELECT_FIRM = "DATA_SELECT_FIRM";
export const ERROR_DATA_SELECT_FIRM = "ERROR_DATA_SELECT_FIRM";

export const SET_CURRENT_PAGE_ACCOUNT = "SET_CURRENT_PAGE_ACCOUNT";
export const SET_SORT_ACCOUNT = "SET_SORT_ACCOUNT";
export const SET_SELECTED_PAGE_SIZE_ACCOUNT = "SET_SELECTED_PAGE_SIZE_ACCOUNT";
export const SET_SEARCH_ACCOUNT = "SET_SEARCH_ACCOUNT";
export const SET_SEARCH_VALUE_ACCOUNT = "SET_SEARCH_VALUE_ACCOUNT";




