import {
    ACTION_ACCOUNT,
    CREATE_ACCOUNT,
    ERROR_CREATE_ACCOUNT,
    ACTION_FETCH_ACCOUNT,
    FETCH_ACCOUNT,
    ERROR_FETCH_ACCOUNT,
    CLEAR_ACCOUNT,
    UPDATE_ACCOUNT,
    ERROR_UPDATE_ACCOUNT,
    CLEAR_ALERT_ACCOUNT,
    FETCH_ACCOUNTS,
    ERROR_FETCH_ACCOUNTS,
    ACTION_FETCH_ACCOUNTS,
    DELETE_ACCOUNT,
    ERROR_DELETE_ACCOUNT,
    ACTIVE_ACCOUNT,
    ERROR_ACTIVE_ACCOUNT,
    WELCOME_ACCOUNT,
    ERROR_WELCOME_ACCOUNT,
    ACTION_DATA_FIELDS_ACCOUNT,
    DATA_FIELDS_ACCOUNT,
    ERROR_DATA_FIELDS_ACCOUNT,
    DATA_SELECT_FIRM,
    ERROR_DATA_SELECT_FIRM,
    CHANGE_PASSWORD_ACCOUNT,
    ERROR_CHANGE_PASSWORD_ACCOUNT,
    SET_CURRENT_PAGE_ACCOUNT,
    SET_SORT_ACCOUNT,
    SET_SELECTED_PAGE_SIZE_ACCOUNT,
    SET_SEARCH_ACCOUNT,
    SET_SEARCH_VALUE_ACCOUNT,
} from './types';

const INIT_STATE = {
    accounts: {
        data: [],
        totalItemCount: 0
    },
    selectedPageSize: 10,
    search: "",
    searchValue: "",
    currentPage: 1,
    sortObj: null,
    accountData: {
        name: '',
        surname: '',
        phone: '',
        phone_code: '',
        phone_iso: '',
        mobile: '',
        mobile_code: '',
        mobile_iso: '',
        email: '',
        firm_id: '',
        firm_address_id: '',
        contact_id: '',
        gender_id: '',
        job_title_id: '',
        role_id: '',
        isOnline: '',
        viber: '',
        whatsapp: '',
        skype: '',
        image: '',
        file: '',
    },
    createAccount: '',
    updateAccount: '',
    changePasswordAccount: '',
    deleteAccount: '',
    activeAccount: '',
    welcomeAccount: '',
    dataFields: {
        firms: [],
        addresses: [],
        contacts: [],
        countries: [],
        jobTitles: [],
        genders: [],
        roles: []
    },
    loadingBtn: false,
    loading: true,
    error: ''
};


const accounts =  (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACTION_ACCOUNT:
            return { ...state, loadingBtn: true, error: '' };
        case CREATE_ACCOUNT:
            return { ...state, loadingBtn: false, createAccount: action.payload, error: '' };
        case ERROR_CREATE_ACCOUNT:
            return { ...state, loadingBtn: false, createAccount: '', error: action.payload.message };
        case ACTION_FETCH_ACCOUNT:
            return { ...state, loading: true, error: '' };
        case FETCH_ACCOUNT:
            return { ...state, loading: false, accountData: action.payload, error: '' };
        case ERROR_FETCH_ACCOUNT:
            return { ...state, loading: false, accountData: '', error: action.payload.message };
        case CLEAR_ACCOUNT:
            return { ...state, loadingBtn: false, loading: false, accountData: INIT_STATE.accountData, error: '' };
        case UPDATE_ACCOUNT:
            return { ...state, loadingBtn: false, updateAccount: action.payload, error: '' };
        case ERROR_UPDATE_ACCOUNT:
            return { ...state, loadingBtn: false, updateAccount: '', error: action.payload.message };
        case CLEAR_ALERT_ACCOUNT:
            return { ...state, updateAccount: '', createAccount: '', deleteAccount: '', activeAccount: '', welcomeAccount: '', changePasswordAccount: '', error: '' };
        case ACTION_FETCH_ACCOUNTS:
            return { ...state, loading: true, error: '' };
        case FETCH_ACCOUNTS:
            return { ...state, loading: false, accounts: action.payload, error: '' };
        case ERROR_FETCH_ACCOUNTS:
            return { ...state, loading: false, accounts: INIT_STATE.accounts, error: action.payload.message };
        case DELETE_ACCOUNT:
            return { ...state, loading: false, deleteAccount: action.payload, error: '' };
        case ERROR_DELETE_ACCOUNT:
            return { ...state, loading: false, deleteAccount: '', error: action.payload.message };
        case ACTIVE_ACCOUNT:
            return { ...state, loading: false, activeAccount: action.payload, error: '' };
        case ERROR_ACTIVE_ACCOUNT:
            return { ...state, loading: false, activeAccount: '', error: action.payload.message };
        case WELCOME_ACCOUNT:
            return { ...state, loading: false, welcomeAccount: action.payload, error: '' };
        case ERROR_WELCOME_ACCOUNT:
            return { ...state, loading: false, welcomeAccount: '', error: action.payload.message };
        case CHANGE_PASSWORD_ACCOUNT:
            return { ...state, loading: false, loadingBtn: false, changePasswordAccount: action.payload, error: '' };
        case ERROR_CHANGE_PASSWORD_ACCOUNT:
            return { ...state, loading: false, loadingBtn: false, changePasswordAccount: '', error: action.payload.message };

        //fetch data for fields
        case ACTION_DATA_FIELDS_ACCOUNT:
            return { ...state, loading: true, error: '' };
        case DATA_FIELDS_ACCOUNT:
            return { ...state, dataFields: action.payload, error: '' };
        case ERROR_DATA_FIELDS_ACCOUNT:
            return { ...state, loading: false, dataFields: INIT_STATE.dataFields, error: action.payload.message };

            //fetch data select for Customers
        case DATA_SELECT_FIRM:
            let new_dataFields = { ...state.dataFields };

            new_dataFields.addresses = action.payload.addresses;
            new_dataFields.contacts = action.payload.contacts;

            return { ...state, dataFields: new_dataFields, error: '' };
        case ERROR_DATA_SELECT_FIRM:
            return { ...state, loading: false, error: action.payload.message };

        //seting list heading
        case SET_CURRENT_PAGE_ACCOUNT:
            return { ...state, currentPage: action.payload };
        case SET_SORT_ACCOUNT:
            return { ...state, sortObj: action.payload };
        case SET_SELECTED_PAGE_SIZE_ACCOUNT:
            return { ...state, selectedPageSize: action.payload, currentPage: 1 };
        case SET_SEARCH_ACCOUNT:
            return { ...state, search: action.payload, currentPage: 1 };
        case SET_SEARCH_VALUE_ACCOUNT:
            return { ...state, searchValue: action.payload };
        default: return state;
    }
}

export default accounts
