import english from "../assets/images/flags/english.png"
import greek from "../assets/images/flags/greek.png"

const languages = {
  el: {
    label: "Greek",
    flag: greek,
  },
  en: {
    label: "English",
    flag: english,
  },
}

export default languages
