/**************** PLACES *********************/
export const ACTION_PLACE = "ACTION_PLACE";
export const CREATE_PLACE = "CREATE_PLACE";
export const ERROR_CREATE_PLACE = "ERROR_CREATE_PLACE";

export const ACTION_FETCH_PLACE = "ACTION_FETCH_PLACE";
export const FETCH_PLACE = "FETCH_PLACE";
export const ERROR_FETCH_PLACE = "ERROR_FETCH_PLACE";
export const CLEAR_PLACE = "CLEAR_PLACE";

export const UPDATE_PLACE = "UPDATE_PLACE";
export const ERROR_UPDATE_PLACE = "ERROR_UPDATE_PLACE";

export const CLEAR_ALERT_PLACE = "CLEAR_ALERT_PLACE";

export const FETCH_PLACES = "FETCH_PLACES";
export const ACTION_FETCH_PLACES = "ACTION_FETCH_PLACES";
export const ERROR_FETCH_PLACES = "ERROR_FETCH_PLACES";

export const DELETE_PLACE = "DELETE_PLACE";
export const ERROR_DELETE_PLACE = "ERROR_DELETE_PLACE";

export const ACTIVE_PLACE = "ACTIVE_PLACE";
export const ERROR_ACTIVE_PLACE = "ERROR_ACTIVE_PLACE";

export const EMAIL_PLACE = "EMAIL_PLACE";
export const ERROR_EMAIL_PLACE = "ERROR_EMAIL_PLACE";

export const ACTION_DATA_FIELDS_PLACE = "ACTION_DATA_FIELDS_PLACE";
export const DATA_FIELDS_PLACE = "DATA_FIELDS_PLACE";
export const ERROR_DATA_FIELDS_PLACE = "ERROR_DATA_FIELDS_PLACE";

export const SET_CURRENT_PAGE_PLACE = "SET_CURRENT_PAGE_PLACE";
export const SET_SORT_PLACE = "SET_SORT_PLACE";
export const SET_SELECTED_PAGE_SIZE_PLACE = "SET_SELECTED_PAGE_SIZE_PLACE";
export const SET_SEARCH_PLACE = "SET_SEARCH_PLACE";
export const SET_SEARCH_VALUE_PLACE = "SET_SEARCH_VALUE_PLACE";




